import React from 'react'
import { ActionContainer, Body, Container, Header, ImageContainer } from './VerticalCard.styled'
import { useScreen } from '@root/utils'

interface Props {
  svg: string
  header: string
  body: string
  actionElement: React.JSX.Element
}

const VerticalCard: React.FC<Props> = ({ svg, header, body, actionElement }) => {
  const { isMobile } = useScreen()

  return (
    <Container>
      <ImageContainer src={svg} alt="svg" isMobile={isMobile}></ImageContainer>
      <Header>{header}</Header>
      <Body>{body}</Body>
      <ActionContainer>{actionElement}</ActionContainer>
    </Container>
  )
}

export default VerticalCard
