import { useQueryParam } from '@hooks'
import { Position } from 'evergreen-ui'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { BasicSideSheet } from '../components/BasicSideSheet'
import { MainMenu } from '../containers/MainMenu'
import useScreen from '../utils/useScreen'
import { AIHelpSheet } from '@containers/AIHelpSheet/AIHelpSheet.tsx'

export const Page: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <StyledPage>
      <ResponsiveMenu />
      <ContentWrapper>{children}</ContentWrapper>
      <AIHelpSheet />
    </StyledPage>
  )
}

const ResponsiveMenu: React.FC = () => {
  const { isMobile } = useScreen()
  const [isMenuOpen, setMenuOpen] = useQueryParam<string>('menu')

  if (isMobile)
    return (
      <BasicSideSheet
        heading="Menu"
        position={Position.LEFT}
        isShown={isMenuOpen === 'true'}
        onClose={() => setMenuOpen(null)}
      >
        <MainMenu width="100%" />
      </BasicSideSheet>
    )

  return <MainMenu isTogglable={true} />
}

const StyledPage = styled(motion.div).attrs({
  initial: false,
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -5 },
  transition: { type: 'tween' }
})`
  height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
`

const ContentWrapper = styled.div`
  flex: 1;
  z-index: 0;
  overflow: auto;
`
