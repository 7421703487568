import { AnalyticsFrequency } from '@constants'
import { network } from '@utils'
import { AxiosResponse } from 'axios'
import { QueryFunction } from './root'

export type AnalyticsField = { field: string; value: number }

export type AnalyticsSerieEntry = { label: string; value: number }

export type AnalyticsSerie = { result: AnalyticsSerieEntry[] }

interface AnalyticsStatProps {
  field?: string
  aggregate?: string
  business_id: string
  date_from?: string
  date_to?: string
  date?: string
  account_type: string[]
  ignore_previous_profit?: boolean
}

interface AnalyticsSeriesProps extends AnalyticsStatProps {
  freq: AnalyticsFrequency
}

export const decorateExp = (s: string): string => [s, '*'].join('')

export const fetchEntryStat: QueryFunction<AnalyticsStatProps, AnalyticsField> = async props => {
  const { business_id, field = 'balance', aggregate = 'sum', ...rest } = props
  const url = `/v1/business/${business_id}/dashboard/entry-stat/`

  const { data } = await network.httpClient.request<
    any,
    AxiosResponse<{ results: AnalyticsField[] }>
  >({
    url,
    method: 'GET',
    params: { aggregate, field, ...rest }
  })

  const result = data.results.find(res => res.field === field)
  return result
}

export const fetchAccountStat: QueryFunction<AnalyticsStatProps, AnalyticsField> = async props => {
  const { business_id, field = 'balance', aggregate = 'sum', ...rest } = props
  const url = `/v1/business/${business_id}/dashboard/account-stat/`

  const { data } = await network.httpClient.request<
    any,
    AxiosResponse<{ results: AnalyticsField[] }>
  >({
    url,
    method: 'GET',
    params: { aggregate, field, ...rest }
  })

  const result = data.results.find(res => res.field === field)
  return result
}

export const fetchSeries: QueryFunction<
  AnalyticsSeriesProps,
  AnalyticsSerieEntry[]
> = async props => {
  const field = 'balance'
  const aggregate = 'sum'
  const { business_id, ...rest } = props
  const url = `/v1/business/${business_id}/dashboard/series/`

  const { data } = await network.httpClient.request<
    any,
    AxiosResponse<{ results: { field: string; result: AnalyticsSerieEntry[] }[] }>
  >({
    url,
    method: 'GET',
    params: { aggregate, field, ...rest }
  })

  const result = data.results.find(res => res.field === field).result

  return result
}

interface FetchFilterProps {
  businessId: string
}

export type IDateRangeKey =
  | 'custom'
  | 'month-this'
  | 'month-prev'
  | 'year-this'
  | 'year-prev'
  | 'period-this'
  | 'period-prev'
  | 'all-history'

export interface IDashboardFilter {
  key: IDateRangeKey
  date_from?: string
  date_to?: string
}

export const fetchFilters: QueryFunction<FetchFilterProps, IDashboardFilter[]> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/dashboard/filters/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface FetchAlertsProps {
  businessId: string
}

export enum DashboardAlertIntents {
  SUGGESTION = 'SUGGESTION',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export enum DashboardAlertTypes {
  BANK_INTEGRATION_CREATION = 'BANK_INTEGRATION_CREATION',
  BANK_INTEGRATION_EXPIRED = 'BANK_INTEGRATION_EXPIRED',
  BANK_INTEGRATION_TO_BE_EXPIRED = 'BANK_INTEGRATION_TO_BE_EXPIRED',
  VAT_PERIOD_MUST_BE_REPORTED = 'VAT_PERIOD_MUST_BE_REPORTED',
  BANK_INTEGRATION_RENEWAL = 'BANK_INTEGRATION_RENEWAL',
  VAT_PERIOD_REPORTING_PENDING = 'VAT_PERIOD_REPORTING_PENDING',
  UNPAID_PURCHASE_INVOICES = 'UNPAID_PURCHASE_INVOICES',
  UNPAID_SALES_INVOICES = 'UNPAID_SALES_INVOICES'
}

export interface IDashboardAlert {
  intent: DashboardAlertIntents
  type: DashboardAlertTypes
  title: string
  message: string
  metadata?: {
    bank_integration_id?: number
    vat_period_id?: number
    period_id?: number
  }
  link?: string
}

export const fetchAlerts: QueryFunction<FetchAlertsProps, IDashboardAlert[]> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/dashboard_alerts/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
