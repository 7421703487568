import { Button, HeaderBlock, Prompt } from '@components'
import { BlueprintType, UserRole } from '@constants'
import { useBusinessContext, usePermissionBoundary } from '@context'
import { AccountingSuggestions } from '@pages/DocumentPage/DocumentDetails/AccountingSuggestions'
import { DocumentFormModal } from '@pages/DocumentPage/DocumentForm'
import {
  collectFromPages,
  deleteDocument,
  fetchDocument,
  flagDocument,
  IDocument,
  lockDocument,
  unflagDocument,
  unlockDocument
} from '@query'
import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaFlag, FaLock, FaLockOpen, FaPen, FaTrash } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { DocumentOverview } from './DocumentOverview'
import { queryKeyForAuditTrail } from './DocumentAuditTrail'

interface DocumentDetailsProps {
  documentId: number
  onClose: () => void
}

export const DocumentDetails: React.FC<DocumentDetailsProps> = ({ documentId, onClose }) => {
  const [t] = useTranslation()
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const { data } = useQuery(
    [businessId, 'documents', documentId],
    () => fetchDocument({ documentId, businessId }),
    {
      onError: () => onClose(),
      initialData: () => {
        const data = queryClient.getQueryData([businessId, 'documents'], {
          exact: false
        })
        if (data) {
          const documents = collectFromPages<IDocument>(data as any)
          return documents.find(d => d.id === documentId)
        }
      }
    }
  )
  const document = data

  const onUpdateSuccess = async () => {
    await queryClient.invalidateQueries([businessId, 'documents'])
    await queryClient.invalidateQueries(queryKeyForAuditTrail(businessId, documentId))
  }

  const deleteMutation = useMutation(() => deleteDocument({ businessId, documentId }), {
    onSuccess: async () => {
      onClose()
      setShowDelete(false)

      await queryClient.removeQueries([businessId, 'documents', documentId])
      await queryClient.removeQueries([businessId, 'documents', documentId, 'entries'])
      await queryClient.invalidateQueries([businessId, 'documents'])

      toast.success(t('document.deletionSuccess'))
    }
  })

  const flagMutation = useMutation(() => flagDocument({ businessId, documentId }), {
    onSuccess: onUpdateSuccess
  })

  const unflagMutation = useMutation(() => unflagDocument({ businessId, documentId }), {
    onSuccess: onUpdateSuccess
  })

  const toggleFlag = () => {
    const isFlagged = document.is_flagged
    const promise = isFlagged ? unflagMutation.mutateAsync() : flagMutation.mutateAsync()
    toast.promise(promise, {
      loading: isFlagged ? t('document.flaggingProgressUnset') : t('document.flaggingProgressSet'),
      success: isFlagged ? t('document.flaggingUnset') : t('document.flaggingSet'),
      error: t('error.oho')
    })
  }

  const lockMutation = useMutation(() => lockDocument({ businessId, documentId }), {
    onSuccess: onUpdateSuccess
  })

  const unlockMutation = useMutation(() => unlockDocument({ businessId, documentId }), {
    onSuccess: onUpdateSuccess
  })

  const menuItems = useMemo(
    () =>
      document
        ? [
            {
              name: document.is_locked ? t('document.unlock') : t('document.lock'),
              Icon: document.is_locked ? FaLockOpen : FaLock,
              onClick: () =>
                document.is_locked ? unlockMutation.mutateAsync() : lockMutation.mutateAsync()
            },
            ...(document.is_locked
              ? []
              : [
                  {
                    name: document.is_flagged ? t('document.unflag') : t('document.flag'),
                    Icon: FaFlag,
                    onClick: () => toggleFlag()
                  },
                  { divider: true },

                  {
                    name: t('general.delete'),
                    Icon: FaTrash,
                    onClick: () => setShowDelete(true),
                    intent: 'danger'
                  }
                ])
          ]
        : [],
    [document]
  )

  const header = useMemo(() => {
    if (document?.is_draft) return t('document.type.draft')
    if (document?.blueprint_type === BlueprintType.PURCHASE) return t('document.type.purchase')
    if (document?.blueprint_type === BlueprintType.SALES) return t('document.type.sales')
    return t('document.type.other')
  }, [document?.blueprint_type])

  if (!document) return null

  return (
    <>
      <StyledWrapper key={`document-${document.id}`}>
        <HeaderBlock header={header} subHeader={<></>} menuItems={isEditor ? menuItems : null} />

        {document.is_draft && isEditor ? (
          <ScrollableContent>
            <AccountingSuggestions document={document} setShowEdit={value => setShowEdit(value)} />
          </ScrollableContent>
        ) : (
          <>
            <ScrollableContent id={'document-tabs'}>
              <DocumentOverview document={document} setShowEdit={value => setShowEdit(value)} />
            </ScrollableContent>

            {!document.is_locked && isEditor && (
              <StyledFooter>
                <Button
                  icon={<FaPen />}
                  onClick={() => setShowEdit(true)}
                  data-test="document-edit-button"
                >
                  {t('general.edit')}
                </Button>
              </StyledFooter>
            )}
          </>
        )}
      </StyledWrapper>

      <DocumentFormModal
        isVisible={showEdit}
        onClose={() => setShowEdit(false)}
        documentId={document.id}
      />

      <Prompt
        isVisible={showDelete}
        title={t('document.deleteTitle')}
        description={t('document.deleteDescription')}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            intent: 'default',
            action: () => setShowDelete(false)
          },
          {
            text: t('general.delete'),
            icon: <FaTrash />,
            intent: 'danger',
            action: async () => {
              await deleteMutation.mutateAsync()
            }
          }
        ]}
      />
    </>
  )
}

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`

const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const StyledFooter = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.md}rem;
  justify-content: space-between;
`
