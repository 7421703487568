import { ActionBar, AnimatedContentLoader, Button, ModalV2 } from '@components'
import { useBusinessContext, useFeature, usePermissionBoundary } from '@context'
import { UserRole } from '@constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { InvoiceForm } from './InvoiceForm'
import { useQuery } from 'react-query'
import { fetchBusiness } from '@root/query'
import { IFilter } from '@root/components/ActionBar/FilterBar/FilterSelection'
import { InvoiceImportWizard } from '@containers/CSVImportWizard/InvoiceImportWizard.tsx'
import { FaFileCsv } from 'react-icons/fa6'
import { FaEnvelope } from 'react-icons/fa6'
import { PurchaseInvoiceEmailImport } from './PurchaseInvoiceEmailImport'

interface Props {
  onSearch: (value: string) => void
  onFilter: (filters: any) => void
  activeFilters: IFilter[]
}

export const InvoicingActionBar: React.FC<Props> = ({ onSearch, onFilter, activeFilters }) => {
  const [showCreate, setShowCreate] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [t] = useTranslation()
  const featureInvoicing = useFeature('feature_invoicing')
  const { businessId } = useBusinessContext()
  const { data: business, isLoading } = useQuery([businessId], () => fetchBusiness({ businessId }))

  const isEmpty = Object.keys(business).length === 0
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const [isEmailInvoiceImportModalOpen, setIsEmailInvoiceImportModalOpen] = useState(false)
  const bulkInvoicing = useFeature('feature_bulk_invoicing')

  const handlePurchaseInvoiceEmailImportModalClose = () => {
    setIsEmailInvoiceImportModalOpen(false)
  }

  const filterButtons = [
    {
      id: 'sent-invoices',
      name: t('invoicing.filters.sentInvoices'),
      filter: { only_sales: true }
    },
    {
      id: 'received-invoices',
      name: t('invoicing.filters.receivedInvoices'),
      filter: { only_purchases: true }
    },
    {
      id: 'is-open',
      name: t('invoicing.filters.isOpen'),
      filter: { is_open: true }
    },
    {
      id: 'is-over-due',
      name: t('invoicing.filters.isOverDue'),
      filter: { is_over_due: true }
    },
    {
      id: 'in-debt-collection',
      name: t('invoicing.filters.inDebtCollection'),
      filter: { in_debt_collection: true }
    }
  ]

  const primaryButtons = featureInvoicing
    ? [
        <Button
          icon={<FaPlus />}
          onClick={() => setShowCreate(true)}
          key="new-invoice-button"
          data-test="new-invoice-button"
        >
          {t('invoicing.actions.create')}
        </Button>,
        // Activate the import button only if the bulk invoicing feature is enabled
        ...(bulkInvoicing
          ? [
              <Button
                key="csv-import"
                icon={<FaFileCsv />}
                onClick={() => setShowImport(true)}
                disabled={!business?.can_invoice}
              >
                {t('invoicing.import.button')}
              </Button>
            ]
          : []),

        <Button
          key="email-invoice-receiving-button"
          icon={<FaEnvelope />}
          onClick={() => {
            setIsEmailInvoiceImportModalOpen(true)
          }}
        >
          {t('invoicing.actions.emailInvoiceReceiving')}
        </Button>
      ]
    : []

  return (
    <>
      <ActionBar
        searchPlaceholder={t('invoicing.actions.search')}
        onSearch={search => onSearch(search)}
        onFilter={filters => onFilter(filters)}
        filterButtons={filterButtons}
        activeFilters={activeFilters}
        primaryButtons={isEditor ? primaryButtons : []}
      ></ActionBar>

      <ModalV2
        isVisible={showCreate}
        header={t('invoicing.form.titleCreate')}
        handleOnClose={() => setShowCreate(false)}
        width={800}
        height={900}
      >
        <AnimatedContentLoader isLoading={isLoading} isEmpty={isEmpty}>
          {business && (
            <InvoiceForm
              onSubmit={() => setShowCreate(false)}
              defaultValues={{
                payment_condition_days: business?.invoicing_default_payment_condition_days,
                penalty_interest: business?.invoicing_default_penalty_interest
              }}
            />
          )}
        </AnimatedContentLoader>
      </ModalV2>

      <ModalV2
        isVisible={showImport}
        header={t('invoicing.import.title')}
        handleOnClose={() => setShowImport(false)}
        width={800}
        height={900}
      >
        <InvoiceImportWizard onComplete={() => setShowImport(false)} />
      </ModalV2>

      <ModalV2
        isVisible={isEmailInvoiceImportModalOpen}
        header={t('invoicing.purchaseInvoiceEmailImport.modalTitle')}
        handleOnClose={handlePurchaseInvoiceEmailImportModalClose}
        width={700}
        height={700}
      >
        <PurchaseInvoiceEmailImport onClose={handlePurchaseInvoiceEmailImportModalClose} />
      </ModalV2>
    </>
  )
}
