import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'

interface Props {
  botId: string
}

export const ChatbaseEmbed: React.FC<Props> = ({ botId }) => {
  const { t } = useTranslation()
  const botUrl = `https://chatbase.co/chatbot-iframe/${botId}`

  return (
    <StyledWrapper>
      <Disclaimer>
        <FaInfoCircle /> <span>{t('components.chatbase.disclaimer')}</span>
      </Disclaimer>
      <StyledIframe src={botUrl} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  overflow: hidden;
`

const StyledIframe = styled.iframe`
  outline: none;
  flex: 1;
  border: none;
`

const Disclaimer = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGray};

  svg {
    fill: ${({ theme }) => theme.colors.nocfoYellow};
    width: ${({ theme }) => theme.iconSize.xs}rem;
    min-width: ${({ theme }) => theme.iconSize.xs}rem;
    height: ${({ theme }) => theme.iconSize.xs}rem;
    min-height: ${({ theme }) => theme.iconSize.xs}rem;
  }
`
