import { Button, HeaderBlock, ModalV2, MonetaryAmount, Prompt } from '@components'
import { BlueprintType, getContactPageUrl, getInvoicingPageUrl, InvoiceStatus } from '@constants'
import { useBusinessContext } from '@context'
import { DocumentFormModal } from '@pages/DocumentPage/DocumentForm'
import {
  acceptInvoice,
  deleteInvoice,
  fetchInvoice,
  setInvoiceAsCreditLoss,
  setInvoiceAsPaid,
  setInvoiceAsUnpaid
} from '@query'
import { formatDate } from '@utils'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaCheckCircle,
  FaCog,
  FaFeatherAlt,
  FaMinusCircle,
  FaMinusSquare,
  FaPaperPlane,
  FaPen,
  FaReply,
  FaTrash
} from 'react-icons/fa'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { InvoiceForm } from './InvoiceForm'

import {
  InvoiceRecurrenceDisabledBadge,
  InvoiceStatusBadge,
  InvoiceSentBadge
} from './InvoiceStatusBadge'
import {
  DetailRow,
  ScrollableContent,
  StyledHeader,
  StyledProductDetails,
  StyledValue,
  StyledValueContainer
} from './InvoicingDetails.styled'
import { SendInvoiceForm } from './SendInvoiceForm'
import InvoiceRecurrenceEditSettings from './InvoiceRecurrenceEditSettings'
import InvoiceRecurrenceInfo from './InvoiceRecurrenceInfo'
import {
  DebtCollectionTracker,
  showDebtCollectionTracker
} from '@pages/InvoicingPage/DebtCollection/DebtCollectionTracker.tsx'
import { DueDateStatusIcon } from './combinedStatus'
import { isOverdue } from '@root/utils/invoicingHelpers'
import { AttachmentSelector } from '@containers/AttachmentSelector'

interface Props {
  invoiceId: number
  onClose: () => void
}

export const InvoiceDetails: React.FC<Props> = ({ invoiceId, onClose }) => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const [showDelete, setShowDelete] = useState(false)
  const [showDocumentForm, setShowDocumentForm] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showSend, setShowSend] = useState(false)
  const [showCreditNote, setShowCreditNote] = useState(false)
  const [showRecurenceEdit, setShowRecurenceEdit] = useState(false)

  const { data } = useQuery(
    [businessId, 'invoices', invoiceId],
    () => fetchInvoice({ businessId, invoiceId }),
    {
      enabled: !showDelete
    }
  )

  const { data: creditNoteForData } = useQuery(
    [businessId, 'invoices', data?.is_credit_note_for],
    () => fetchInvoice({ businessId, invoiceId: data?.is_credit_note_for }),
    {
      enabled: Boolean(data?.is_credit_note_for)
    }
  )

  const creditNotesResults = useQueries(
    (data?.credit_notes || []).map(invoiceId => ({
      queryKey: [businessId, 'invoices', invoiceId],
      queryFn: () => fetchInvoice({ businessId, invoiceId })
    }))
  )
  const creditNotes = creditNotesResults.every(result => result.isSuccess)
    ? creditNotesResults.map(result => result.data)
    : []

  const { mutateAsync: deleteMutation, isLoading: isDeleting } = useMutation(
    () => deleteInvoice({ businessId, invoiceId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'invoices'])
        await queryClient.invalidateQueries([businessId, 'combined_invoices'])
        onClose()
        setShowDelete(false)
      }
    }
  )

  const { mutateAsync: acceptMutation, isLoading: isAccepting } = useMutation(
    () => acceptInvoice({ businessId, invoiceId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'invoices'])
        await queryClient.invalidateQueries([businessId, 'combined_invoices'])
      }
    }
  )

  const { mutateAsync: setAsPaidMutation } = useMutation(
    () => setInvoiceAsPaid({ businessId, invoiceId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'invoices'])
        await queryClient.invalidateQueries([businessId, 'combined_invoices'])
      }
    }
  )

  const { mutateAsync: setAsCreditLossMutation } = useMutation(
    () => setInvoiceAsCreditLoss({ businessId, invoiceId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'invoices'])
        await queryClient.invalidateQueries([businessId, 'combined_invoices'])
      }
    }
  )

  const { mutateAsync: setAsUnpaidMutation } = useMutation(
    () => setInvoiceAsUnpaid({ businessId, invoiceId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'invoices'])
        await queryClient.invalidateQueries([businessId, 'combined_invoices'])
      }
    }
  )

  const isDraft = data?.status === InvoiceStatus.DRAFT
  const isRecurringActively = data?.is_recurrence_active
  const isTypeOfRecurring = data?.is_recurrence_applied
  const isRecurrenceExceeded = data?.is_recurrence_end_exceeded
  const showRecurrenceDisabledBadge =
    !isRecurringActively && !isRecurrenceExceeded && isTypeOfRecurring
  const showRecurrenceInfo = isTypeOfRecurring && !showRecurrenceDisabledBadge
  const isPaid = data?.status === InvoiceStatus.PAID
  const isCreditLoss = data?.status === InvoiceStatus.CREDIT_LOSS
  const isSent = data?.last_delivery_at !== null
  const inDebtCollection = !!data?.is_registered_to_kravia

  const menuItems = React.useMemo(() => {
    const menuItems = []

    if (isDraft) {
      menuItems.push({
        Icon: FaPen,
        name: t('invoicing.details.edit'),
        intent: 'default',
        onClick: () => setShowEdit(true)
      })

      menuItems.push({
        Icon: FaCheckCircle,
        name: t('invoicing.details.accept'),
        intent: 'default',
        disabled: isAccepting,
        onClick: () => !isAccepting && acceptMutation()
      })
    } else {
      menuItems.push({
        Icon: FaFeatherAlt,
        name: t('invoicing.details.createDocument'),
        intent: 'default',
        onClick: () => setShowDocumentForm(true)
      })

      menuItems.push({
        Icon: FaReply,
        name: t('invoicing.details.refund'),
        intent: 'default',
        onClick: () => setShowCreditNote(true)
      })
    }

    if (!isDraft) {
      menuItems.push({
        Icon: FaPaperPlane,
        name: t('invoicing.details.send'),
        intent: 'default',
        onClick: () => setShowSend(true)
      })
    }

    if (isPaid || isCreditLoss) {
      menuItems.push({
        Icon: FaMinusSquare,
        name: t('invoicing.actions.markUnpaid'),
        intent: 'default',
        onClick: () => setAsUnpaidMutation()
      })
    } else if (!isDraft) {
      menuItems.push({
        Icon: FaCheckCircle,
        name: t('invoicing.actions.markPaid'),
        intent: 'default',
        onClick: () => setAsPaidMutation()
      })
      menuItems.push({
        Icon: FaMinusCircle,
        name: t('invoicing.actions.markCreditLoss'),
        intent: 'default',
        onClick: () => setAsCreditLossMutation()
      })
    }

    if (isRecurringActively && !isDraft) {
      menuItems.push({
        Icon: FaCog,
        name: t('invoicing.actions.modifyRecurrence'),
        intent: 'default',
        onClick: () => setShowRecurenceEdit(true)
      })
    }

    if (menuItems.length > 0)
      menuItems.push({
        divider: true
      })

    menuItems.push({
      Icon: FaTrash,
      name: t('invoicing.details.delete'),
      intent: 'danger',
      onClick: () => setShowDelete(true)
    })

    return menuItems
  }, [isDraft, isPaid, isCreditLoss])

  const actionButton = useMemo(() => {
    if (inDebtCollection) return null
    if (isDraft)
      return (
        <Button
          data-test="invoice-form-accept-invoice-button"
          intent="success"
          showSpinner={isAccepting}
          disabled={isAccepting}
          icon={<FaPen />}
          onClick={() => acceptMutation()}
        >
          {t('invoicing.actions.accept')}
        </Button>
      )

    if (showDebtCollectionTracker(data)) return null

    if (!isSent && !isTypeOfRecurring)
      return (
        <Button intent="primary" icon={<FaPaperPlane />} onClick={() => setShowSend(true)}>
          {t('invoicing.actions.send')}
        </Button>
      )

    if (!(isPaid || isCreditLoss) && isSent)
      return (
        <Button intent="success" icon={<FaCheckCircle />} onClick={() => setAsPaidMutation()}>
          {t('invoicing.actions.markPaid')}
        </Button>
      )

    return null
  }, [isDraft, isSent, isPaid, isAccepting, isCreditLoss])

  if (!data) return null
  return (
    <StyledProductDetails>
      <HeaderBlock
        header={
          data?.is_credit_note_for
            ? t('invoicing.details.creditNoteTitle')
            : t('invoicing.details.title')
        }
        subHeader={<></>}
        menuItems={menuItems}
      />

      {/* EDIT FORM */}
      <ModalV2
        header={t('invoicing.form.titleModify')}
        isVisible={showEdit}
        handleOnClose={() => setShowEdit(false)}
        width={800}
        height={900}
      >
        <InvoiceForm
          editInvoiceId={data.id}
          defaultValues={data}
          onSubmit={() => setShowEdit(false)}
        />
      </ModalV2>

      {/* CREDIT NOTE FORM */}
      <ModalV2
        header={t('invoicing.form.titleModify', { invoiceNumber: data?.invoice_number })}
        isVisible={showCreditNote}
        handleOnClose={() => setShowCreditNote(false)}
        width={800}
        height={900}
      >
        <InvoiceForm
          defaultValues={data}
          isCreditNoteFor={data?.id}
          onSubmit={() => setShowCreditNote(false)}
        />
      </ModalV2>

      <ModalV2
        header={t('invoicing.send.title')}
        isVisible={showSend}
        handleOnClose={() => setShowSend(false)}
      >
        <SendInvoiceForm
          invoice={data}
          onSubmit={() => setShowSend(false)}
          onCancel={() => setShowSend(false)}
        />
      </ModalV2>

      {/* RECURRENCE EDIT */}
      <InvoiceRecurrenceEditSettings
        invoice={data}
        handleOnClose={() => setShowRecurenceEdit(false)}
        showPrompt={showRecurenceEdit}
      />

      <ScrollableContent>
        {showDebtCollectionTracker(data) && (
          <DetailRow>
            <DebtCollectionTracker invoice={data} />
          </DetailRow>
        )}

        {showRecurrenceInfo && <InvoiceRecurrenceInfo invoice={data}></InvoiceRecurrenceInfo>}

        <DetailRow>
          <StyledHeader>{t('invoicing.details.status')}</StyledHeader>
          <StyledValue>
            <InvoiceStatusBadge status={data?.status} />
            {showRecurrenceDisabledBadge && <InvoiceRecurrenceDisabledBadge />}
            {data?.last_delivery_at && <InvoiceSentBadge />}
          </StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('invoicing.details.number')}</StyledHeader>
          <StyledValue>
            {data?.invoice_number && data?.status != InvoiceStatus.DRAFT ? (
              data.invoice_number
            ) : (
              <MutedText>{t('invoicing.details.numberNotGenerated')}</MutedText>
            )}
          </StyledValue>
        </DetailRow>

        {data?.reference && (
          <DetailRow>
            <StyledHeader>{t('invoicing.details.reference')}</StyledHeader>
            <StyledValue>{data?.reference}</StyledValue>
          </DetailRow>
        )}

        {data?.is_credit_note_for && (
          <DetailRow>
            <StyledHeader>{t('invoicing.details.creditNoteNumber')}</StyledHeader>
            <StyledValue>
              <Link
                to={getInvoicingPageUrl(businessId, { id: creditNoteForData?.id, type: 'sales' })}
              >
                {creditNoteForData?.friendly_name}
              </Link>
            </StyledValue>
          </DetailRow>
        )}

        {creditNotes.length > 0 && (
          <DetailRow>
            <StyledHeader>{t('invoicing.details.creditNoteDone')}</StyledHeader>
            {creditNotes.map(({ id, friendly_name }) => (
              <StyledValue key={`credit-note-${id}`}>
                <Link to={getInvoicingPageUrl(businessId, { id, type: 'sales' })}>
                  {friendly_name}
                </Link>
              </StyledValue>
            ))}
          </DetailRow>
        )}

        <DetailRow>
          <StyledHeader>{t('invoicing.details.receiver')}</StyledHeader>
          <StyledValue data-test="invoice-details-receiver">
            <Link to={getContactPageUrl(businessId, { id: data?.receiver })}>
              {data?.receiver_info?.name}
            </Link>
          </StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('invoicing.details.invoicingDate')}</StyledHeader>
          <StyledValue data-test="invoice-details-date">
            {formatDate(data?.invoicing_date)}
          </StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('invoicing.details.dueDate')}</StyledHeader>

          <StyledValueContainer>
            <StyledValue data-test="invoice-details-due-date">
              {formatDate(data?.due_date)}
            </StyledValue>
            {isOverdue(data) && <DueDateStatusIcon />}
          </StyledValueContainer>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('invoicing.details.rows')}</StyledHeader>
          <ProductRowTable>
            {data?.rows.map(row => (
              <ProductRowRow key={`invoice-row-${row.id}`}>
                <ProductRowCell data-test="invoice-details-quantity">
                  {row.product_count} {row.unit}
                </ProductRowCell>
                <ProductRowCell data-test="invoice-details-name" style={{ width: '50%' }}>
                  {row.name}
                </ProductRowCell>
                <ProductRowCell data-test="invoice-details-amount" style={{ textAlign: 'right' }}>
                  <MonetaryAmount value={row.total_amount + row.total_vat_amount} />
                </ProductRowCell>
              </ProductRowRow>
            ))}

            {data?.rows.length > 1 && (
              <ProductRowRow
                data-test="invoice-details-total-amount"
                className="total"
                style={{ textAlign: 'right' }}
              >
                = <MonetaryAmount value={data?.total_amount + data?.total_vat_amount} />
              </ProductRowRow>
            )}
          </ProductRowTable>
        </DetailRow>

        {data?.pdf?.id && (
          <DetailRow>
            <StyledHeader>{t('invoicing.details.preview')}</StyledHeader>
            <AttachmentSelector value={[data?.pdf?.id]} disabled={true} />
          </DetailRow>
        )}

        {data.attachments.length > 0 && (
          <DetailRow>
            <StyledHeader>{t('invoicing.details.attachments')}</StyledHeader>
            <AttachmentSelector value={data.attachments} disabled={true} />
          </DetailRow>
        )}
      </ScrollableContent>

      <ContentFooter>{actionButton}</ContentFooter>

      <Prompt
        isVisible={showDelete}
        title={t('invoicing.details.deleteTitle')}
        description={
          isDraft
            ? t('invoicing.details.deleteDescription')
            : t('invoicing.details.deleteNonDraftDescription')
        }
        buttons={[
          {
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            text: t('general.delete'),
            icon: <FaTrash />,
            intent: 'danger',
            action: () => deleteMutation(),
            showSpinner: isDeleting,
            disabled: isDeleting
          }
        ]}
        onClose={() => setShowDelete(false)}
      />

      {data && (
        <DocumentFormModal
          isVisible={showDocumentForm}
          onClose={() => setShowDocumentForm(false)}
          defaults={{
            date: data?.invoicing_date,
            description: `${data?.invoice_number} ${data?.receiver_info?.name}`,
            contact_id: data?.receiver,
            attachment_ids: [data?.pdf?.id],
            blueprint_type: BlueprintType.SALES,
            blueprint: {}
          }}
        />
      )}
    </StyledProductDetails>
  )
}

const ProductRowTable = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.xs}rem 0;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

const ProductRowRow = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;

  &.total {
    justify-content: flex-end;
    font-weight: 500;
  }
`

const ProductRowCell = styled.div`
  display: table-cell;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const MutedText = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`
