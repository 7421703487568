import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ChatbaseEmbed } from '@containers/ChatbaseEmbed'
import { FeatureWrapper } from '@context'

export const AIHelpContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ContentWrapper>
      <FeatureWrapper feature="feature_bank_integrations">
        <ChatbaseEmbed botId={t('aiHelp.botId')} />
      </FeatureWrapper>

      <FeatureWrapper feature="feature_bank_integrations" reverse>
        <ChatbaseEmbed botId={t('aiHelp.freeBotId')} />
      </FeatureWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
`
