import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Page = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.menuBg};
`

export const BusinessesPageWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  overflow: auto;
`

export const StyledBusinessesPage = styled(motion.div).attrs({
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -5 },
  transition: { type: 'tween' }
})``

export const BusinessesWrapper = styled.div`
  flex: 1;
  max-width: 1200px;
  margin: auto;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
`

export const BusinessListContainer = styled(motion.div).attrs({
  layout: true,
  animate: { height: 'auto', borderRadius: 15 }
})`
  display: flex;
  flex-wrap: wrap;
  background: white;
  padding: 1rem 0;
  justify-content: space-around;
  margin-bottom: ${({ theme }) => theme.spacing.xxl}rem;
`

export const BusinessesHeaderWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  margin-bottom: ${({ theme }) => theme.spacing.xxl}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
  flex-wrap: wrap;

  & > * {
    align-self: center;
  }
`

export const BusinessesHeader = styled.div`
  flex: 1;
  margin: 0;
`

export const AnimatedCard = styled(motion.div).attrs({
  layout: 'position',
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})``

export const BusinessCard = styled(Link)`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.lg}rem;
  padding: ${({ theme }) => `${theme.spacing.xl}rem ${theme.spacing.md}rem`};
  border-radius: 10px;
  background: transparent;
  width: 200px;
  cursor: pointer;

  & > * {
    align-self: center;
    max-width: 200px;
    white-space: pre-line;
    word-break: break-word;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
    text-decoration: none;
  }
`

export const BusinessCardTitle = styled.h4`
  text-align: center;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

export const BusinessCardID = styled.span`
  font-variant-numeric: tabular-nums;
  text-align: center;
  color: ${({ theme }) => theme.colors.metalGray};
`

export const ButtonWrapper = styled.div`
  display: flex;

  & > a {
    margin-right: 0.6rem;
  }
`
