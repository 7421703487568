import { ISubFolder } from '@root/query'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { HiChevronRight } from 'react-icons/hi'
import styled from 'styled-components'

interface IProps {
  breadcrumbs: ISubFolder[]
  searchValue?: string
  onClick: (folderId: number) => void
}

const BreadCrumbs: React.FC<IProps> = ({ breadcrumbs, searchValue, onClick: setFolderId }) => {
  const [t] = useTranslation()

  if (searchValue) {
    return (
      <BreadCrumbWrapper>
        <StyledCrumb disabled={true}>
          {t('files.browser.breadcrumb.searchHint', { searchValue })}
        </StyledCrumb>
      </BreadCrumbWrapper>
    )
  }

  return (
    <BreadCrumbWrapper>
      <Crumb
        id={undefined}
        name={t('files.browser.breadcrumb.home')}
        key="root"
        onClick={setFolderId}
      />

      {breadcrumbs.map(({ id, name }) => (
        <Fragment key={`crumb-${id}`}>
          <HiChevronRight size={16} style={{ minWidth: 16, minHeight: 16 }} />
          <Crumb id={id} name={name} key={id} onClick={setFolderId} />
        </Fragment>
      ))}
    </BreadCrumbWrapper>
  )
}

export default BreadCrumbs

const Crumb: React.FC<{ id: number; name: string; onClick: (folderId: number) => void }> = ({
  id,
  name,
  onClick: setFolderId
}) => {
  return <StyledCrumb onClick={() => setFolderId(id)}>{name}</StyledCrumb>
}

const BreadCrumbWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.metalGray};
  height: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledCrumb = styled.a<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  ${({ disabled }) => (disabled ? 'cursor: none;' : 'cursor: pointer;')}

  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  text-decoration: none !important;
  border: 2px solid transparent;
  border-radius: 0.2rem;
`
