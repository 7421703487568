import { DoubleModal } from '@components'
import { IDocument } from '@query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentForm } from './DocumentForm'
import { DocumentFormExtraDetailView } from './DocumentFormExtraDetailView'

interface Props {
  documentId?: number
  defaults?: Partial<IDocument>
  isVisible: boolean
  onClose: () => void
  enableCache?: boolean
}

export const DocumentFormModal: React.FC<Props> = ({
  documentId,
  defaults,
  isVisible,
  onClose,
  enableCache
}) => {
  const { t } = useTranslation()
  const [selectedAttachments, setSelectedAttachments] = useState<number[]>()
  const isEdit = !!documentId

  return (
    <DoubleModal
      isVisible={isVisible}
      oneModalWidthMin={480}
      oneModalWidthMax={1000}
      height={1100}
      mainContent={
        <DocumentForm
          documentId={documentId}
          defaults={defaults}
          onClose={onClose}
          enableCache={enableCache}
          onValuesUpdate={data => {
            setSelectedAttachments(data?.attachment_ids || [])
          }}
        />
      }
      mainHeader={isEdit ? t('document.edit') : t('document.addNew')}
      onClose={() => onClose()}
      secondaryContent={
        <DocumentFormExtraDetailView documentId={documentId} attachmentIds={selectedAttachments} />
      }
    />
  )
}
