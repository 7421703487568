import { AnimatedContentLoader } from '@components'
import { AccountType } from '@constants'
import { useBusinessContext, useDateRangeContext } from '@context'
import { decorateExp, fetchAccountStat } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { RatioChart } from './RatioChart'
import { DimensionProvider } from './styles'

export const CapitalChart: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { selectedRange, isReady } = useDateRangeContext()

  const { data: capital, isLoading: isCapitalLoading } = useQuery(
    ['dashboard', 'capital', businessId, selectedRange?.date_to],
    () =>
      fetchAccountStat({
        business_id: businessId,
        field: 'balance',
        aggregate: 'sum',
        date: selectedRange?.date_to,
        account_type: [
          AccountType.LIA_PRE,
          AccountType.LIA_EQU,
          decorateExp(AccountType.REV),
          decorateExp(AccountType.EXP)
        ],
        ignore_previous_profit: true
      }),
    { enabled: isReady }
  )

  const { data: liabilities, isLoading: isLiabilitiesLoading } = useQuery(
    ['dashboard', 'liability', businessId, selectedRange?.date_to],
    () =>
      fetchAccountStat({
        business_id: businessId,
        field: 'balance',
        aggregate: 'sum',
        date: selectedRange?.date_to,
        account_type: [
          AccountType.LIA_DEB,
          AccountType.LIA_DUE,
          AccountType.LIA_ACC,
          AccountType.LIA_VAT
        ],
        ignore_previous_profit: true
      }),
    { enabled: isReady }
  )

  return (
    <WidgetContainer width="100%" height="100%">
      <AnimatedContentLoader isLoading={!isReady || isCapitalLoading || isLiabilitiesLoading}>
        <DimensionProvider width="100%" height="100%" style={{ flex: 1 }}>
          <Wrapper>
            <RatioChart
              labels={[
                t('dashboard.capitalChart.capital'),
                t('dashboard.capitalChart.liabilities')
              ]}
              values={[capital?.value / 100 || 0, liabilities?.value / 100 || 0]}
            />
          </Wrapper>
        </DimensionProvider>
      </AnimatedContentLoader>
    </WidgetContainer>
  )
}

const WidgetContainer = styled(DimensionProvider)`
  padding: ${({ theme }) => theme.spacing.md}rem;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  flex: 1;
`
