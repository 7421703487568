import {
  AnimatedContentLoader,
  Button,
  FileThumbnail,
  FilterBar,
  SearchInput,
  UploadDropzone,
  UploadFilesButton
} from '@components'
import { useVirtualList } from '@hooks'
import {
  collectFromPages,
  fetchFiles,
  fetchFolder,
  fetchFolders,
  IFile,
  ISubFolder,
  uploadFile
} from '@query'
import { formatDate } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaCircle, FaCloudUploadAlt } from 'react-icons/fa'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { useAllowUpload } from '@hooks/useAllowUpload.tsx'
import { useQuery } from 'react-query'
import Folder from './Folder'
import BreadCrumbs from './BreadCrumbs'

interface FileSelectorProps {
  businessId: string
  isDisabled?: boolean
  initialSelections?: number[]
  onClose: (ids: number[]) => void
}

export const FileSelector: React.FC<FileSelectorProps> = ({
  businessId,
  isDisabled = false,
  initialSelections = [],
  onClose
}) => {
  const [t] = useTranslation()
  const [selectedFiles, setSelectedFiles] = useState<number[]>(initialSelections)
  const [search, setSearch] = useState('')
  const queryClient = useQueryClient()
  const [filters, setFilters] = useState<Record<string, any>>(null)
  const allowUpload = useAllowUpload()
  const [folderId, setFolderId] = useState<number>()

  const fetchFoldersForView = useCallback(async (folderId?: number) => {
    // Fetch specific folder contents if folder is open. Otherwise fetch from root.
    if (folderId) {
      const data = await fetchFolder({ businessId, folderId })
      return { folders: data.children, breadcrumbs: data.breadcrumbs }
    } else {
      const data = await fetchFolders({ businessId }, { page_size: 9999 })
      return { folders: data.results, breadcrumbs: [] }
    }
  }, [])

  const hideFolders = !!search || !!filters

  const { data: dataFolders, isLoading: isFoldersLoading } = useQuery(
    [businessId, 'folders', folderId],
    () => fetchFoldersForView(folderId),
    {
      enabled: !hideFolders
    }
  )

  const folders = dataFolders?.folders
  const breadcrumbs = dataFolders?.breadcrumbs || []

  const queryParams = {
    search: search ? search : null,
    folder: search ? null : folderId,
    root: hideFolders ? null : !folderId,
    ...filters
  }

  const {
    data: fileData,
    isLoading: isFilesLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [businessId, 'files', queryParams],
    ({ pageParam }) =>
      fetchFiles({ businessId }, { page: pageParam ? pageParam : 1, ...queryParams }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )
  const files = collectFromPages<IFile>(fileData)

  const foldersAndFiles = useMemo(
    () => [
      ...(hideFolders
        ? []
        : folders?.map(item => ({
            type: 'folder',
            item
          })) || []),
      ...(files?.map(item => ({
        type: 'file',
        item
      })) || [])
    ],
    [folders, files]
  )

  const uploadMutation = useMutation<IFile, unknown, File>(
    file => uploadFile(file, { businessId, fileName: file.name, folderId }),
    {
      onSuccess: async function (file) {
        await queryClient.invalidateQueries([businessId, 'files'])
        setSelectedFiles(selectedFiles => {
          const newFiles = [...selectedFiles, file.id]
          return newFiles
        })
      }
    }
  )
  const uploadFileCallback = file => uploadMutation.mutateAsync(file)

  const toggleSelection = (selectedId: number) => {
    const index = selectedFiles.findIndex(id => id === selectedId)
    if (index !== -1) {
      const newFiles = [...selectedFiles]
      newFiles.splice(index, 1)
      setSelectedFiles(newFiles)
    } else {
      const newFiles = [...selectedFiles, selectedId]
      setSelectedFiles(newFiles)
    }
  }

  const { renderItems, scrollRef } = useVirtualList({
    items: foldersAndFiles,
    itemSize: 54,
    onScrollBottom: () => hasNextPage && fetchNextPage()
  })

  const handleOnSelect = () => {
    onClose(selectedFiles)
  }

  return (
    <StyledFileSelector>
      <PaddedContainer>
        <SearchInput
          placeholder={t('components.fileSelector.searchPlaceholder')}
          onSearch={searchTerm => setSearch(searchTerm)}
        />
      </PaddedContainer>

      <div>
        <FilterBar
          onFilter={filter => setFilters(filter)}
          filters={[
            {
              id: 'not-used',
              name: t('files.filters.notUsed'),
              filter: { is_used: false }
            }
          ]}
        />
      </div>

      <BreadCrumbs
        breadcrumbs={breadcrumbs}
        searchValue={search}
        onClick={(folderId: number) => setFolderId(folderId)}
      />

      <FileList ref={scrollRef}>
        <UploadDropzone uploadFile={uploadFileCallback} disabled={!allowUpload}>
          <AnimatedContentLoader
            isLoading={isFilesLoading || isFoldersLoading}
            isEmpty={foldersAndFiles.length === 0}
            isEmptyDescription={t('components.fileSelector.searchNotFound')}
          >
            {renderItems((data, style) => {
              if (data.type === 'folder') {
                const folder = data.item as ISubFolder
                return (
                  <Folder
                    key={`folder-${folder.id}`}
                    folder={folder}
                    onClick={(folderId: number) => setFolderId(folderId)}
                  />
                )
              }

              if (data.type === 'file') {
                const file = data.item as IFile
                return (
                  <SelectableFile
                    key={`file-${file.id}`}
                    style={style}
                    onClick={() => toggleSelection(file.id)}
                  >
                    <Column width={32}>
                      <AnimatePresence mode="wait">
                        {selectedFiles.indexOf(file.id) !== -1 ? (
                          <IconWrapper key="selected">
                            <FaCheckCircle size={16} className="icon selected" />
                          </IconWrapper>
                        ) : (
                          <IconWrapper key="not-selected">
                            <FaCircle size={16} className="icon" />
                          </IconWrapper>
                        )}
                      </AnimatePresence>
                    </Column>
                    <Column width={50}>
                      <FileThumbnail
                        size={32}
                        filetype={file.type}
                        thumb={file.file}
                        blurhash={file.blurhash}
                      />
                    </Column>
                    <Column width={3}>
                      <FileName>{file.name}</FileName>
                      <FileType>{formatDate(file.created_at)}</FileType>
                    </Column>
                  </SelectableFile>
                )
              }
            })}
          </AnimatedContentLoader>
        </UploadDropzone>
      </FileList>
      <ButtonContainer>
        <UploadFilesButton
          type="button"
          icon={<FaCloudUploadAlt />}
          uploadFile={uploadFileCallback}
        >
          {t('components.fileSelector.uploadFiles')}
        </UploadFilesButton>

        <Button
          type="button"
          icon={<FaCheckCircle />}
          intent="primary"
          onClick={handleOnSelect}
          disabled={isDisabled || uploadMutation.isLoading}
        >
          <ButtonContent>
            <ButtonText>{t('components.fileSelector.done')}</ButtonText>
            <ButtonCount>{selectedFiles.length}</ButtonCount>
          </ButtonContent>
        </Button>
      </ButtonContainer>
    </StyledFileSelector>
  )
}

const StyledFileSelector = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const IconWrapper = styled(motion.div).attrs({
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1.0, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
  transition: { duration: 0.2 }
})`
  display: flex;

  * {
    align-self: center;
  }
`

const FileList = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
`

const PaddedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const ButtonContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SelectableFile = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
  cursor: pointer;
  border-radius: 1rem;

  &:hover {
    background: #f6f7f9;
  }

  & > * {
    align-self: center;
  }

  .icon {
    fill: ${({ theme }) => theme.colors.neutralGray};

    &.selected {
      fill: ${({ theme }) => theme.colors.nocfoBlue};
    }
  }
`

const Column = styled.div<{ width: number }>`
  min-width: ${({ width }) => width}px;
`

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FileType = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const ButtonContent = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`

const ButtonText = styled.span`
  align-self: center;
`

const ButtonCount = styled.span`
  background: white;
  color: ${({ theme }) => theme.colors.nocfoBlue};
  margin-left: 0.4rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  padding: 0 0.4rem;
  border-radius: 0.6rem;
  right: 0;
  align-self: center;
`
