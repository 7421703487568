import { ISubFolder } from '@root/query'
import React from 'react'
import { FaFolder } from 'react-icons/fa'
import styled from 'styled-components'

const ICON_SIZE = 32

interface IProps {
  folder: ISubFolder
  onClick: (folderId: number) => void
}

const Folder: React.FC<IProps> = ({ folder, onClick: onFolderClick }) => {
  return (
    <Container onClick={() => onFolderClick(folder.id)}>
      <Column>
        <EmptyFiller />
      </Column>
      <Column>
        <FolderIcon size={ICON_SIZE} />
      </Column>
      <Column>
        <FolderName>{folder.name}</FolderName>
      </Column>
    </Container>
  )
}

export default Folder

const Container = styled.a`
  display: flex;
  width: fill-available;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;

  cursor: pointer;

  border-radius: 1rem;

  &:hover {
    background: #f6f7f9;
  }
`

const Column = styled.div`
  display: flex;
`

const EmptyFiller = styled.div`
  width: 32px;
`

const FolderIcon = styled(FaFolder)`
  color: ${({ theme }) => theme.colors.metalGray};
`

const FolderName = styled.div`
  padding-left: ${({ theme }) => theme.spacing.md}rem;
  align-self: center;
`
