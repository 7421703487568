import {
  Alert,
  AnimatedContentLoader,
  Button,
  DocumentWidget,
  FilePreview2,
  HeaderBlock,
  Input,
  Tab,
  Tabs
} from '@components'
import { getInvoicingPageUrl, UserRole } from '@constants'
import { useBusinessContext, usePermissionBoundary } from '@context'
import {
  deleteFile,
  fetchCombinedInvoices,
  fetchFile,
  ICombinedInvoice,
  IFile,
  IInvoice,
  IPurchaseInvoice,
  updateFile
} from '@query'
import { formatDate, setAPIErrors } from '@utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaCloudDownloadAlt, FaInfoCircle, FaSave, FaStream, FaTrashAlt } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { AttachmentAnalysisPreview } from '@pages/FilesPage/components/AnalysisPreview.tsx'
import { useDebugMode } from '@hooks'
import { downloadFileToBeSaved } from '@root/utils/downloadFileToBeSaved'
import { Link } from 'react-router-dom'

interface Props {
  fileId: number
  onClose: () => void
}

interface FormInputs {
  name: string
  tags: { name: string }[]
}

const invoiceLinkAndDisplayName = (
  invoice: ICombinedInvoice,
  businessId: string
): [string, string] => {
  const invoiceId = invoice.item.id
  if (invoice.type === 'purchase') {
    const purchaseInvoice = invoice.item as IPurchaseInvoice
    const displayName = `${formatDate(purchaseInvoice.invoicing_date)} ${
      purchaseInvoice.sender_name
    }`
    const link = getInvoicingPageUrl(businessId, {
      id: invoiceId,
      type: 'purchase'
    })
    return [link, displayName]
  } else if (invoice.type === 'sales') {
    const salesInvoice = invoice.item as IInvoice
    const displayName = `${formatDate(salesInvoice.invoicing_date)} ${
      salesInvoice.receiver_info.name
    }`
    const link = getInvoicingPageUrl(businessId, {
      id: invoiceId,
      type: 'sales'
    })
    return [link, displayName]
  }
}

export const FileDetailView: React.FC<Props> = ({ fileId, onClose }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const [isDebugModeOn] = useDebugMode()
  const {
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting }
  } = useForm<FormInputs>()

  const queryClient = useQueryClient()

  const {
    mutateAsync: deleteMutation,
    isLoading: isDeleting,
    isSuccess: isDeleted
  } = useMutation(() => deleteFile({ fileId, businessId }), {
    onSuccess: async () => {
      onClose()
      await queryClient.invalidateQueries([businessId, 'files'])
    }
  })

  const { data, isLoading } = useQuery(
    [businessId, 'files', fileId],
    () => fetchFile({ businessId, fileId }),
    {
      onSuccess: data => reset({ name: data?.name }),
      enabled: !(isDeleting || isDeleted)
    }
  )

  const { data: invoiceData, isLoading: isInvoiceDataLoading } = useQuery(
    [businessId, 'invoice', fileId],
    () => fetchCombinedInvoices({ businessId }, { original_attachment_id: fileId }),
    {
      enabled: !(isDeleting || isDeleted)
    }
  )

  const showInvoiceLink = invoiceData?.results?.length == 1
  const [invoiceLink, invoiceDisplayName] = showInvoiceLink
    ? invoiceLinkAndDisplayName(invoiceData.results[0], businessId)
    : ['', '']

  const updateMutation = useMutation<IFile, unknown, Partial<IFile>>(
    data => updateFile({ businessId, fileId }, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'files'])
      },
      onError: ({ status, data }) => {
        status === 400 && setAPIErrors(data, setError)
      }
    }
  )

  const onSubmit = handleSubmit(data => updateMutation.mutate(data))

  return (
    <>
      <FileDetailWrapper>
        <HeaderBlock header={t('files.fileDetails.header')} subHeader={<></>} />

        <FileDetailContent>
          <Tabs>
            <Tab key="info" icon={<FaInfoCircle />} name={t('files.fileDetails.info')}>
              <AnimatedContentLoader isLoading={isLoading || isInvoiceDataLoading}>
                {showInvoiceLink && (
                  <DetailRow>
                    <DetailHeader>{t('files.fileDetails.invoiceLink')}</DetailHeader>
                    <StyledLink to={invoiceLink}>{invoiceDisplayName}</StyledLink>
                  </DetailRow>
                )}

                <PreviewWrapper>{data && <FilePreview2 file={data} />}</PreviewWrapper>

                {isDebugModeOn && <AttachmentAnalysisPreview attachment={data} />}

                <FileDetailForm key={fileId} onSubmit={onSubmit}>
                  <fieldset disabled={!isEditor}>
                    <Input
                      id="file-name"
                      label={t('files.fileDetails.fileName')}
                      {...register('name', {
                        required: { value: true, message: t('validation.required') }
                      })}
                      errors={errors.name}
                      required={true}
                    />

                    <div style={{ display: 'flex' }}>
                      <a
                        data-test="download-file-link"
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          downloadFileToBeSaved(
                            businessId,
                            fileId,
                            data?.name,
                            t('general.downloadError')
                          )
                        }
                      >
                        <Button type="button" icon={<FaCloudDownloadAlt />}>
                          {t('files.fileDetails.download')}
                        </Button>
                      </a>
                      <div style={{ flex: 1 }} />
                      {isEditor && (
                        <Button
                          icon={<FaSave />}
                          intent="success"
                          type="submit"
                          disabled={!isDirty}
                          showSpinner={isSubmitting}
                        >
                          {t('general.save')}
                        </Button>
                      )}
                    </div>
                  </fieldset>
                </FileDetailForm>
              </AnimatedContentLoader>
            </Tab>

            <Tab key="docs" icon={<FaStream />} name={t('files.fileDetails.attachedHeader')}>
              <DocumentWidget
                header={t('files.fileDetails.attachedDescription')}
                noData={t('files.fileDetails.attachedEmpty')}
                fileId={data?.id}
                height={400}
              />
            </Tab>

            {isEditor && (
              <Tab key="delete" icon={<FaTrashAlt />} name={t('files.fileDetails.delete')}>
                <Alert
                  type="error"
                  title={t('files.fileDetails.cannotDelete')}
                  isVisible={!data?.is_deletable}
                  description={t('files.fileDetails.cannotDeleteDescription')}
                />

                <Button
                  icon={<FaTrashAlt />}
                  type="button"
                  intent="danger"
                  onClick={() => deleteMutation()}
                  style={{ marginLeft: 'auto' }}
                  disabled={!data?.is_deletable}
                  showSpinner={isDeleting}
                >
                  {t('general.delete')}
                </Button>
              </Tab>
            )}
          </Tabs>
        </FileDetailContent>
      </FileDetailWrapper>
    </>
  )
}

const PreviewWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem 0;
`

const FileDetailWrapper = styled.div`
  height: 100%;
  background: white;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`

const FileDetailContent = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const FileDetailForm = styled.form`
  width: 100%;
`

const DetailRow = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing.md}rem;
`
const DetailHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: flex-start;
`
const StyledLink = styled(Link)`
  width: fit-content;
`
