import React from 'react'
import { IHolviAccount } from '@query/holvi.ts'
import { Button } from '@components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BankAccount } from '@pages/SettingsPage/sections/IntegrationSettings/BankAccount.tsx'
import { Link } from 'react-router-dom'
import { getBankIntegrationPageURL } from '@constants'
import { useBusinessContext } from '@context'

interface Props {
  accounts: IHolviAccount[]
}

export const HolviAccountMapper: React.FC<Props> = ({ accounts }) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  const accountHeader = {
    psd: t('settings.holvi.account.psd'),
    credit: t('settings.holvi.account.credit')
  }

  return (
    <AccountsWrapper>
      {accounts
        // Show only enabled accounts
        .filter(({ enabled }) => enabled)
        .map(({ id, iban, last_sync_at, type, name }, idx) => (
          <BankAccount
            key={`account-${idx}`}
            accountId={id}
            accountName={name || accountHeader[type]}
            accountIdentifier={iban}
            lastSyncAt={last_sync_at}
            isMissingInfo={false}
          />
        ))}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to={getBankIntegrationPageURL(businessId, 'holvi-edit')}>
          <Button>{t('general.edit')}</Button>
        </Link>
      </div>
    </AccountsWrapper>
  )
}

const AccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
`
