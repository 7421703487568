import { ICurrentUser, SubscriptionTier } from '@root/query'

interface IUserDataToGTM {
  event: string
  user_id: number
  subscription_tier: SubscriptionTier
}

type GTMEvents = 'login'

export const sendUserDataToGTM = (user: ICurrentUser, event: GTMEvents) => {
  try {
    if (!window.dataLayer) return

    window.dataLayer = window.dataLayer || []
    const userData: IUserDataToGTM = {
      event,
      user_id: user.id,
      subscription_tier: user.subscription_tier
    }

    window.dataLayer.push(userData)
  } catch (error) {
    console.log('Error in sending Google User ID')
    console.log(error)
  }
}
