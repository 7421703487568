import styled from 'styled-components'

import React from 'react'
import { AnimatedContentLoader, Button, CopyValue, ImageMessageBox } from '@root/components'
import mailBox from '@assets/undraw/undraw_mailbox_re_dvds.svg'
import { useQuery } from 'react-query'
import { useBusinessContext } from '@root/context'
import { fetchEmailInvoiceAddress } from '@root/query'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  onClose: () => void
}

export const PurchaseInvoiceEmailImport: React.FC<Props> = ({ onClose }) => {
  const { businessId } = useBusinessContext()
  const { data, isLoading } = useQuery([businessId, 'email_receive_address'], () =>
    fetchEmailInvoiceAddress({ businessId })
  )
  const [t] = useTranslation()

  return (
    <StyledPage>
      <ImageMessageBox imgSrc={mailBox} childrenStyle={{ maxWidth: '100%' }}>
        <h4>{t('invoicing.purchaseInvoiceEmailImport.header')}</h4>
        <p>
          {
            <Trans i18nKey={'invoicing.purchaseInvoiceEmailImport.description'}>
              <a
                href={t('link.docs.purchaseInvoiceEmailImport')}
                rel="noreferrer"
                target="_blank"
              />
            </Trans>
          }
        </p>

        <AnimatedContentLoader isLoading={isLoading}>
          <CopyValue value={data?.email}></CopyValue>
        </AnimatedContentLoader>
      </ImageMessageBox>
      <Footer>
        <FooterButtonContainer>
          <Button onClick={() => onClose()}>{t('general.close')}</Button>
        </FooterButtonContainer>
      </Footer>
    </StyledPage>
  )
}

const StyledPage = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.neutralWhite};
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  margin-top: auto;
`

const FooterButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
