import {
  Alert,
  AnimatedContentLoader,
  Button,
  HeaderBlock,
  ImageMessageBox,
  ModalV2
} from '@components'
import { AttachmentAnalysisStatus, BlueprintType, PurchaseInvoiceImportSource } from '@constants'
import { useBusinessContext } from '@context'
import { DocumentFormModal } from '@pages/DocumentPage/DocumentForm'
import { fetchPurchaseInvoice, updatePurchaseInvoice } from '@query'
import { formatDate } from '@utils'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaFeatherAlt, FaMinusSquare, FaPen } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getCombinedInvoiceStatusAlertType,
  getCombinedPurchaseInvoiceStatus
} from './combinedStatus'
import { PurchaseInvoiceStatusBadge } from './InvoiceStatusBadge'
import {
  ContentFooter,
  DetailRow,
  ScrollableContent,
  StyledHeader,
  StyledProductDetails,
  StyledValue
} from './InvoicingDetails.styled'
import { PurchaseInvoicePaymentInfo } from './PurchaseInvoicePaymentInfo'
import { AttachmentSelector } from '@containers/AttachmentSelector'
import svgAnalyzing from '@assets/undraw/undraw_file_analysis_8k9b-2.svg'
import PurchaseInvoiceForm from './PurchaseInvoiceForm'

interface Props {
  invoiceId: number
  onClose: () => void
}

export const PurchaseInvoiceDetails: React.FC<Props> = ({ invoiceId: purchaseInvoiceId }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [showDocumentForm, setShowDocumentForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)

  const { data, isLoading } = useQuery([businessId, 'purchase_invoices', purchaseInvoiceId], () =>
    fetchPurchaseInvoice({ businessId, purchaseInvoiceId })
  )

  const isImportedFromEmail = data?.import_source === PurchaseInvoiceImportSource.EMAIL

  const isEmailPurchaseInvoiceDataExtractionInProgress =
    (data?.original_attachment?.analysis_status === AttachmentAnalysisStatus.IN_PROGRESS ||
      data?.original_attachment?.analysis_status === AttachmentAnalysisStatus.PENDING) &&
    isImportedFromEmail

  const markAsPaid = useMutation<unknown, unknown, boolean>(
    isPaid =>
      updatePurchaseInvoice(
        { businessId, purchaseInvoiceId },
        {
          is_paid: isPaid
        }
      ),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([businessId, 'purchase_invoices']),
          queryClient.invalidateQueries([businessId, 'combined_invoices'])
        ])
      }
    }
  )

  const allowPurchaseInvoiceEdit =
    isImportedFromEmail && !isEmailPurchaseInvoiceDataExtractionInProgress && !data?.is_paid

  const menuItems = useMemo(() => {
    const items = [
      {
        Icon: FaFeatherAlt,
        name: t('invoicing.details.createDocument'),
        intent: 'default',
        onClick: () => setShowDocumentForm(true)
      },
      ...(!data?.is_paid
        ? [
            {
              Icon: FaCheckCircle,
              name: t('invoicing.purchaseInvoiceDetails.markAsPaid'),
              intent: 'default',
              onClick: () => markAsPaid.mutateAsync(true)
            }
          ]
        : [
            {
              Icon: FaMinusSquare,
              name: t('invoicing.purchaseInvoiceDetails.markAsUnpaid'),
              intent: 'default',
              onClick: () => markAsPaid.mutateAsync(false)
            }
          ]),

      ...(allowPurchaseInvoiceEdit
        ? [
            {
              Icon: FaPen,
              name: t('general.edit'),
              intent: 'default',
              onClick: () => setShowEditForm(true)
            }
          ]
        : [])
    ]
    return items
  }, [data?.is_paid])

  const combinedStatus = data ? getCombinedPurchaseInvoiceStatus(data) : null
  const alertType = getCombinedInvoiceStatusAlertType(combinedStatus?.status)

  return (
    <>
      <StyledProductDetails>
        <HeaderBlock
          header={t('invoicing.purchaseInvoiceDetails.title')}
          subHeader={<></>}
          menuItems={menuItems}
        />

        <ScrollableContent>
          <AnimatedContentLoader isLoading={isLoading}>
            {data && (
              <>
                {alertType && (
                  <Alert
                    type={alertType}
                    description={t(`invoicing.combinedStatus.${combinedStatus?.status}`)}
                  />
                )}

                {isEmailPurchaseInvoiceDataExtractionInProgress && (
                  <ImageMessageBox imgSrc={svgAnalyzing}>
                    <h3>{t('invoicing.purchaseInvoiceDetails.analysingMessage.h3')}</h3>
                    <p>{t('invoicing.purchaseInvoiceDetails.analysingMessage.p')}</p>
                  </ImageMessageBox>
                )}

                {data?.sender_name && (
                  <DetailRow>
                    <StyledHeader>{t('invoicing.purchaseInvoiceDetails.sender')}</StyledHeader>
                    <StyledValue>{data.sender_name}</StyledValue>
                  </DetailRow>
                )}

                {data?.invoicing_date && (
                  <DetailRow>
                    <StyledHeader>
                      {t('invoicing.purchaseInvoiceDetails.invoicingDate')}
                    </StyledHeader>
                    <StyledValue>{formatDate(data.invoicing_date)}</StyledValue>
                  </DetailRow>
                )}

                {data?.due_date && (
                  <DetailRow>
                    <StyledHeader>{t('invoicing.purchaseInvoiceDetails.dueDate')}</StyledHeader>
                    <StyledValue>{formatDate(data.due_date)}</StyledValue>
                  </DetailRow>
                )}

                <DetailRow>
                  <StyledHeader>{t('invoicing.purchaseInvoiceDetails.status')}</StyledHeader>
                  <StyledValue>
                    <PurchaseInvoiceStatusBadge
                      isPaid={data.is_paid}
                      isPastDue={data.is_past_due}
                      hideIcon
                      size="md"
                    />
                  </StyledValue>
                </DetailRow>

                {data?.original_attachment?.id && (
                  <DetailRow>
                    <StyledHeader>{t('invoicing.purchaseInvoiceDetails.preview')}</StyledHeader>
                    <AttachmentSelector
                      value={[data.original_attachment.id]}
                      disabled={true}
                      onChange={() => null}
                    />
                  </DetailRow>
                )}

                {data?.attachments?.length > 0 && (
                  <DetailRow>
                    <StyledHeader>{t('invoicing.purchaseInvoiceDetails.attachments')}</StyledHeader>
                    <AttachmentSelector
                      value={data.attachments}
                      disabled={true}
                      onChange={() => null}
                    />
                  </DetailRow>
                )}

                {allowPurchaseInvoiceEdit && (
                  <Alert
                    type={'magic'}
                    description={
                      <p>{t('invoicing.purchaseInvoiceDetails.analysisDone.description')}</p>
                    }
                  />
                )}

                {!isEmailPurchaseInvoiceDataExtractionInProgress && (
                  <PurchaseInvoicePaymentInfo invoice={data} />
                )}
              </>
            )}
          </AnimatedContentLoader>
        </ScrollableContent>
        <ContentFooter>
          {allowPurchaseInvoiceEdit && (
            <Button icon={<FaPen />} intent="default" onClick={() => setShowEditForm(true)}>
              {t('general.edit')}
            </Button>
          )}

          {!data?.is_paid && (
            <Button
              icon={<FaCheckCircle />}
              intent="success"
              onClick={() => markAsPaid.mutateAsync(true)}
              disabled={markAsPaid.isLoading}
            >
              {t('invoicing.purchaseInvoiceDetails.markAsPaid')}
            </Button>
          )}
        </ContentFooter>
      </StyledProductDetails>

      <DocumentFormModal
        isVisible={showDocumentForm}
        onClose={() => setShowDocumentForm(false)}
        defaults={{
          date: data?.invoicing_date,
          description: data?.sender_name,
          blueprint_type: BlueprintType.SALES,
          attachment_ids: [data?.original_attachment?.id]
        }}
      />

      <ModalV2
        isVisible={showEditForm}
        header={t('invoicing.purchaseInvoiceForm.title')}
        handleOnClose={() => setShowEditForm(false)}
        width={600}
        height={900}
      >
        <PurchaseInvoiceForm purchaseInvoice={data} onClose={() => setShowEditForm(false)} />
      </ModalV2>
    </>
  )
}
