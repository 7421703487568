import { Alert, AnimatedContentLoader, Button, TeaserView } from '@components'
import { getSettingsPageUrl } from '@constants'
import { useBusinessContext, useFeature } from '@context'
import {
  fetchFinancialStatement,
  IFinancialStatement,
  IPeriod,
  updateFinancialStatement
} from '@query'
import { omitEmptyValues, openLinkInTab } from '@utils'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { FaFileDownload, FaSave } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FinancialStatementSection } from './FinancialStatementSection'

interface Props {
  period: IPeriod
}

interface Section {
  showKeyName: keyof IFinancialStatement
  textKeyName: keyof IFinancialStatement
  sectionTitle?: string
  sectionBadge?: string
  sectionInfo?: string
}

const SAVE_AND_DOWNLOAD_BTN_ID = 'save-and-download-btn'

export const FinancialStatementForm: React.FC<Props> = ({ period }) => {
  const hasFeaturePermission = useFeature('feature_financial_statement') === true
  const {
    businessId,
    data: { has_business_address: hasBusinessAddress }
  } = useBusinessContext()
  const {
    t,
    i18n: { language: currentLanguage }
  } = useTranslation()

  const showLanguageDisclaimer = useMemo(
    () => currentLanguage?.toLowerCase() !== 'fi',
    [currentLanguage]
  )

  const sections = useMemo<Section[]>(
    () => [
      {
        showKeyName: 'show_pma_1_01',
        textKeyName: 'text_pma_1_01',
        sectionTitle: 'Tieto tilinpäätöksen laatimisessa käytetyistä säännöstöistä',
        sectionBadge: 'PMA 1:1.5 §',
        sectionInfo: `Tilinpäätöksen laatimisessa on noudatettu pien- ja mikroyrityksen tilinpäätöksessä esitettävistä tiedoista annetun asetuksen (PMA) mikroyritysäännöstöä.`
      },
      {
        showKeyName: 'show_pma_3_01',
        textKeyName: 'text_pma_3_01',
        sectionTitle: 'Tilinpäätöksen laatimisperiaatteet',
        sectionBadge: 'PMA 3:1 §',
        sectionInfo:
          'Liitetietona on esitettävä tilinpäätöstä laadittaessa noudatetut arvostusperiaatteet ja -menetelmät sekä jaksotusperiaatteet ja -menetelmät siltä osin kuin ne ovat pienyritykselle valinnaisia.'
      },
      {
        showKeyName: 'show_pma_3_02',
        textKeyName: 'text_pma_3_02',
        sectionTitle: 'Poikkeukselliset erät',
        sectionBadge: 'PMA 3:2 §',
        sectionInfo:
          'Liitetietona on esitettävä tieto poikkeuksellisten tuotto- ja kuluerien määrästä ja sisällöstä, jolleivät erät ole epäolennaisia.'
      },
      {
        showKeyName: 'show_pma_3_03',
        textKeyName: 'text_pma_3_03',
        sectionTitle: 'Tilikauden jälkeiset olennaiset tapahtumat',
        sectionBadge: 'PMA 3:3 §',
        sectionInfo:
          'Liitetietona on esitettävä tiedot tilikauden päättymiseen jälkeisistä olennaisista tapahtumista, jotka eivät muuten ilmene tilinpäätöksestä. Lisäksi on esitettävä arvio tällaisten tapahtumien taloudellisesta merkityksestä.'
      },
      {
        showKeyName: 'show_pma_3_04',
        textKeyName: 'text_pma_3_04',
        sectionTitle: 'Arvonkorotusrahasto',
        sectionBadge: 'PMA 3:4 §',
        sectionInfo: `Jos pienyritys on korottanut kirjanpitolain 5 luvun 17 §:ssä säädetyllä tavalla pysyviin vastaaviin kuuluvan hyödykkeen poistamatonta hankintamenoa, liitetietona on esitettävä:

1. arvonkorostusrahaston muutokset tilikauden aikana;

2. selvitys arvonkorotusrahaston muutoksiin liittyvistä verovaikutuksista; sekä

3. arvonkorotuksen kohteiden yhteenlaskettu kirjanpitoarvo ennen arvonkorotusta.`
      },
      {
        showKeyName: 'show_pma_3_05',
        textKeyName: 'text_pma_3_05',
        sectionTitle: 'Tiedot käypään arvoon merkitsemisestä',
        sectionBadge: 'PMA 3:4 §',
        sectionInfo:
          'Pienyrityksen on esitettävä tarvittavat liitetiedot, mikäli pienyritys on noudattanut kirjanpitolain 5 luvun 2 a tai 2 b §:ää rahoitusvälineiden tai muiden mainittujen pykälien tarkoittamien omaisuuserien arvostamisessa.'
      },
      {
        showKeyName: 'show_pma_3_06',
        textKeyName: 'text_pma_3_06',
        sectionTitle: 'Pitkäaikaiset lainat',
        sectionBadge: 'PMA 3:6 §',
        sectionInfo:
          'Liitetietona on esitettävä yhteenlaskettu määrä sellaisista pitkäaikaiseen vieraaseen pääomaan kuuluvista veloista, jotka erääntyvät myöhemmin kuin viiden vuoden kuluttua.'
      },
      {
        showKeyName: 'show_pma_3_07',
        textKeyName: 'text_pma_3_07',
        sectionTitle:
          'Annetut vakuudet ja taseen ulkopuoliset sitoumukset ja järjestelyt sekä eläkevastuut',
        sectionBadge: 'PMA 3:7 §',
        sectionInfo: `Liitetietona on esitettävä annetut vakuudet ja taseen ulkopuoliset sitoumukset ja järjestelyt sekä eläkevastuut`
      },
      {
        showKeyName: 'show_pma_3_08',
        textKeyName: 'text_pma_3_08',
        sectionTitle: 'Tiedot emoyrityksestä',
        sectionBadge: 'PMA 3:8 §',
        sectionInfo:
          'Liitetietona on esitettävä emoyrityksen nimi ja kotipaikka siitä konsernitilinpäätöksen kohteena olevasta konsernista, johon pienyritys välittömimmin kuuluu.'
      },
      {
        showKeyName: 'show_pma_3_09',
        textKeyName: 'text_pma_3_09',
        sectionTitle:
          'Lainat toimitusjohtajalle ja hallintoelimiin kuuluville sekä heidän puolestaan annetut sitoumukset',
        sectionBadge: 'PMA 3:9 §',
        sectionInfo: `Toimitusjohtajasta, hallituksen jäsenistä ja varajäsenistä sekä vastaaviin toimielimiin kuuluvista henkilöistä on ilmoitettava toimielinkohtaisena liitetietona:

1. heille myönnettyjen rahalainojen yhteenlaskettu määrä samoin kuin määrän vähennys ja lisäys tilikauden aikana sekä lainojen pääasialliset korko- ja muut ehdot;

2. heitä koskevien vakuuksien ja vastuusitoumusten yhteenlaskettu määrä ja pääasiallinen sisältö; sekä

3. eläkesitoumukset näistä tehtävistä.`
      },
      {
        showKeyName: 'show_pma_3_10',
        textKeyName: 'text_pma_3_10',
        sectionTitle: 'Liiketoimet intressitahojen kanssa',
        sectionBadge: 'PMA 3:10 §',
        sectionInfo:
          'Liitetietona on esitettävä tiedot pienyrityksen ja tämän intressitahoihin kuuluvien välisistä liiketoimista, jos ne ovat olennaisia eikä niitä ole toteutettu tavanomaisin kaupallisin ehdoin.'
      },
      {
        showKeyName: 'show_pma_3_11',
        textKeyName: 'text_pma_3_11',
        sectionTitle: 'Henkilöstö',
        sectionBadge: 'PMA 3:11 §',
        sectionInfo:
          'Liitetietona on esitettävä henkilöstön keskimääräinen lukumäärä tilikauden aikana.'
      },
      {
        showKeyName: 'show_pma_3_12',
        textKeyName: 'text_pma_3_12',
        sectionTitle: 'Omien osakkeiden ja osuuksien hankinnat ja luovutukset',
        sectionBadge: 'PMA 3:12 §',
        sectionInfo: `Liitetietona on esitettävä:

1. tilikauden aikana tehtyjen omien osakkeiden tai osakkeita vastaavien oman pääoman ehtoisten osuuksien hankintojen perusteet;

2. tilikauden aikana hankittujen tai luovutettujen omien osakkeiden tai osuuksien lukumäärä ja nimellisarvo tai, jos nimellisarvoa ei ole, kirjanpidollinen vasta-arvo sekä suhteellinen osuus, jota omat osakkeet tai osuudet edustavat merkitystä omasta pääomasta;

3. vastikkeellisissa hankinnoissa tai luovutuksissa omista osakkeista tai osuuksista suoritettu vastike; sekä

4. yrityksen hankkimien ja sen hallussa olevien kaikkien omien osakkeiden tai osuuksien lukumäärä ja nimellisarvo tai, jos nimellisarvoa ei ole, kirjanpidollinen vasta-arvo sekä suhteellinen osuus, jota omat osakkeet tai osuudet edustavat merkitystä pääomasta.`
      },
      {
        showKeyName: 'show_pma_3_13',
        textKeyName: 'text_pma_3_13',
        sectionTitle: 'Toimintakertomusta vastaavat tiedot',
        sectionBadge: 'PMA 3:13 §',
        sectionInfo:
          'Pienyritys ei ole velvollinen laatimaan kirjanpitolain 3 luvun 1a §:n mukaista toimintakertomusta. Jos mainitussa pykälässä tarkoitettu tieto on tarpeen oikean ja riittävän kuvan antamiseksi asianomaisesta pienyrityksestä, se tulee kuitenkin esittää liitetietona.'
      },
      {
        showKeyName: 'show_changes_in_equity',
        textKeyName: 'text_changes_in_equity',
        sectionTitle: 'Oman pääoman muutokset',
        sectionBadge: 'MUUTA',
        sectionInfo:
          'Oman pääoman muutokset sekä hallituksen ehdotus jakokelpoisen vapaan oman pääoman käytöstä. Tilinpäätösdokumenttiin lisätään tähän kohtaan taulukko oman pääoman muutoksista.'
      },
      {
        showKeyName: 'show_accounting_and_supporting_list',
        textKeyName: 'text_accounting_and_supporting_list',
        sectionTitle: 'Luettelo kirjanpidosta ja aineistosta',
        sectionBadge: 'MUUTA',
        sectionInfo: 'Luettelo kirjanpidosta ja aineistosta'
      },
      {
        showKeyName: 'show_financial_statement_signature',
        textKeyName: 'text_financial_statement_signature',
        sectionTitle: 'Tilinpäätöksen allekirjoitus',
        sectionBadge: 'MUUTA',
        sectionInfo: 'Tilinpäätöksen allekirjoitus'
      }
    ],
    []
  )

  const defaultValues = useMemo(
    () => ({
      text_pma_1_01:
        'Tilinpäätöksen laatimisessa on noudatettu pien- ja mikroyrityksen tilinpäätöksessä esitettävistä tiedoista annetun asetuksen (PMA) mikroyritysäännöstöä.',
      text_pma_3_11: 'Tilikaudella yhtiön palveluksessa oli keskimäärin XX henkilöä.',
      text_accounting_and_supporting_list: `# Kirjanpidot ja säilytystapa

Seuraavat kirjanpidot löytyvät sähköisesti NOCFO-kirjanpitopalvelusta:

- Tilinpäätös
- Pääkirja ja päiväkirja
- Tilikohtainen tase ja Tuloslaskelma
- Tililuettelo
- Tase-erittelyt

# Tositteiden lajit ja säilytystavat

Seuraavat tositteet löytyvät sähköisesti NOCFO-kirjanpitopalvelusta:

- Myyntilaskut
- Ostolaskut
- Rahaliikennetositteet
- Muistiotositteet
`
    }),
    [currentLanguage]
  )

  const { reset, control, handleSubmit } = useForm<IFinancialStatement>({
    defaultValues
  })

  const { isLoading } = useQuery(
    [businessId, 'financial_statement', period.id],
    () => fetchFinancialStatement({ businessId, periodId: period.id }),
    {
      enabled: hasFeaturePermission,
      onSuccess: data => {
        const resetData = { ...defaultValues, ...omitEmptyValues(data) }
        reset(resetData, { keepDirtyValues: true })
      }
    }
  )

  const updateMutation = useMutation<unknown, unknown, IFinancialStatement>(
    data => updateFinancialStatement({ businessId, periodId: period.id }, data),
    {
      onSuccess: data => {
        reset(data)
      }
    }
  )

  const onSubmit = handleSubmit(async data => {
    const _window = window as any
    const shouldSaveAndDownload = _window?.event?.submitter?.id === SAVE_AND_DOWNLOAD_BTN_ID
    const promise = updateMutation.mutateAsync(data)

    toast.promise(promise, {
      loading: t('general.saving'),
      success: t('general.success'),
      error: t('error.genericRetry')
    })

    const newData = (await promise) as IFinancialStatement

    // Trigger file download if user clicked on the "Tallenna ja lataa" button
    if (shouldSaveAndDownload) {
      openLinkInTab(newData?.financial_statement_docx)
    }
  })

  if (!hasFeaturePermission) {
    return (
      <ScrollableContent>
        <TeaserView
          header={t('teaser.financialStatement.header')}
          subHeader={t('teaser.invoicing.subHeader')}
          features={[
            t('teaser.feature.7'),
            t('teaser.feature.2'),
            t('teaser.feature.4'),
            t('teaser.feature.5')
          ]}
          maxWidth="800px"
        />
      </ScrollableContent>
    )
  }

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      <StyledForm onSubmit={onSubmit}>
        <ScrollableContent>
          <Alert
            type="warning"
            isVisible={!hasBusinessAddress}
            title={t('period.financialStatement.missingAddressTitle')}
            description={
              <Trans i18nKey="period.financialStatement.missingAddressDescription">
                <Link to={getSettingsPageUrl(businessId, { page: 'settings' })} />
              </Trans>
            }
          />

          <Alert
            isVisible={showLanguageDisclaimer}
            title={t('period.financialStatement.onlyInFinnishTitle')}
            description={t('period.financialStatement.onlyInFinnishDescription')}
            type="warning"
          />

          <Alert
            title={t('period.financialStatement.instructionTitle')}
            description={t('period.financialStatement.instructionDescription')}
          />
          <br />

          {sections.map(
            ({ sectionTitle, sectionBadge, sectionInfo, showKeyName, textKeyName }, index) => (
              <FinancialStatementSection
                key={`section-${index}`}
                sectionTitle={sectionTitle}
                sectionBadge={sectionBadge}
                sectionInfo={sectionInfo}
                showKeyName={showKeyName}
                textKeyName={textKeyName}
                control={control}
              />
            )
          )}
        </ScrollableContent>

        <FormFooter>
          <Button icon={<FaSave />} disabled={updateMutation.isLoading}>
            {'Tallenna'}
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            icon={<FaFileDownload />}
            id={SAVE_AND_DOWNLOAD_BTN_ID}
            intent="success"
            showSpinner={updateMutation.isLoading}
            disabled={updateMutation.isLoading}
          >
            {'Tallenna ja lataa'}
          </Button>
        </FormFooter>
      </StyledForm>
    </AnimatedContentLoader>
  )
}

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  height: 100%;
`

const FormFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
`
