import { motion, Variants } from 'framer-motion'
import styled from 'styled-components'

const animation: Variants = {
  hidden: { opacity: 0, y: 100, scale: 1.2 },
  show: { opacity: 1, y: 0, scale: 1 }
}

export const Container = styled(motion.div).attrs({
  variants: animation
})`
  display: flex;
  flex: 1 20rem;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutralWhite};

  max-width: 35rem;

  border-radius: 1rem;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);

  padding: ${({ theme }) => theme.spacing.xl}rem ${({ theme }) => theme.spacing.xxl}rem;
`

export const ImageContainer = styled.img<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? 10 : 15)}rem;
  height: ${({ isMobile }) => (isMobile ? 10 : 15)}rem;
`
export const Header = styled.h3`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

export const Body = styled.p`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl}rem;
`

export const ActionContainer = styled.div`
  width: 100%;
  margin-top: auto;
`
