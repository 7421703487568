import {
  Alert,
  AnimatedContentLoader,
  CopyValue,
  DoubleColumnLayout,
  DoubleColumnLayoutColumn,
  SingleColumnLayout,
  TeaserView
} from '@components'
import { useBusinessContext, useFeature } from '@context'
import { useLocalStorage, useQueryParam } from '@hooks'
import { collectFromPages, fetchCombinedInvoices, fetchEmailInvoiceAddress } from '@query'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components'
import { InvoiceDetails } from './InvoiceDetails'
import { InvoiceList } from './InvoiceList'
import { InvoicingActionBar } from './InvoicingActionBar'
import { InvoicingOnboarding } from './InvoicingOnboarding'
import { PurchaseInvoiceDetails } from './PurchaseInvoiceDetails'
import { IFilter } from '@root/components/ActionBar/FilterBar/FilterSelection'

export const InvoicingPage: React.FC = () => {
  const [t] = useTranslation()
  const showTeaserView =
    useFeature('feature_invoicing') === false && !useFeature('feature_show_received_einvoices')
  const [search, setSearch] = useState('')
  const {
    businessId,
    data: {
      onboarding_updated_invoicing_settings,
      onboarding_created_invoicing_contact,
      onboarding_created_invoicing_product
    }
  } = useBusinessContext()
  const [filters, setFilter] = useLocalStorage<IFilter[]>(`invoices-filters-${businessId}`, [])
  const [selectedId, setSelectedId] = useQueryParam<number>('id', value => parseInt(value, 10))
  const [selectedType] = useQueryParam<number>('type')
  const showOnboarding = !(
    onboarding_updated_invoicing_settings &&
    onboarding_created_invoicing_contact &&
    onboarding_created_invoicing_product
  )

  const filtersReduced = { ...filters?.reduce((prev, current) => ({ ...prev, ...current }), {}) }

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'combined_invoices', { search, filtersReduced }],
    ({ pageParam }) =>
      fetchCombinedInvoices({ businessId }, { page: pageParam || 1, search, ...filtersReduced }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev,
      enabled: !showTeaserView
    }
  )
  const combinedInvoices = collectFromPages(data)

  const onClose = () => {
    setSelectedId(undefined)
  }

  if (showTeaserView) {
    return (
      <SingleColumnLayout header={t('invoicing.mainTitle')}>
        <TeaserView
          header={t('teaser.invoicing.header')}
          subHeader={t('teaser.invoicing.subHeader')}
          features={[
            t('teaser.feature.2'),
            t('teaser.feature.1'),
            t('teaser.feature.3'),
            t('teaser.feature.4'),
            t('teaser.feature.5')
          ]}
          maxWidth="800px"
        />
      </SingleColumnLayout>
    )
  }

  if (showOnboarding) {
    return (
      <SingleColumnLayout header={t('invoicing.mainTitle')}>
        <InvoicingOnboarding />
      </SingleColumnLayout>
    )
  }

  return (
    <DoubleColumnLayout
      header={t('invoicing.mainTitle')}
      isRightVisible={Boolean(selectedId)}
      onRightClose={() => onClose()}
    >
      <DoubleColumnLayoutColumn>
        <PageContentWrapper>
          <InvoicingActionBar
            onSearch={value => setSearch(value)}
            onFilter={value => setFilter(value)}
            activeFilters={filters}
          />
          <EmailInvoicingCallToActionAlert />
          <PeriodListWrapper>
            <AnimatedContentLoader
              isLoading={isLoading}
              isEmpty={combinedInvoices.length === 0}
              isEmptyDescription={t('invoicing.emptyDescription')}
            >
              <InvoiceList
                combinedInvoices={combinedInvoices}
                onScrollBottom={() => hasNextPage && fetchNextPage()}
              />
            </AnimatedContentLoader>
          </PeriodListWrapper>
        </PageContentWrapper>
      </DoubleColumnLayoutColumn>
      <DoubleColumnLayoutColumn innerKey={selectedId} isRight={true}>
        {selectedType === 'sales' && (
          <InvoiceDetails invoiceId={selectedId} onClose={() => onClose()} />
        )}
        {selectedType === 'purchase' && (
          <PurchaseInvoiceDetails invoiceId={selectedId} onClose={() => onClose()} />
        )}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const EmailInvoicingCallToActionAlert: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { data: dataEmailReceiveAddress, isLoading: isLoadingEmailReceiveAddress } = useQuery(
    [businessId, 'email_receive_address'],
    () => fetchEmailInvoiceAddress({ businessId })
  )

  return (
    <Alert
      type={'info'}
      title={t('invoicing.purchaseInvoiceEmailImport.header')}
      dismissable={true}
      dismissableKey={`purchase-invoice-email-import-${businessId}`}
      description={
        <>
          <p>
            <Trans i18nKey={'invoicing.purchaseInvoiceEmailImport.description'}>
              <a
                href={t('link.docs.purchaseInvoiceEmailImport')}
                rel="noreferrer"
                target="_blank"
              />
            </Trans>
          </p>
          <AnimatedContentLoader isLoading={isLoadingEmailReceiveAddress}>
            <CopyValue value={dataEmailReceiveAddress?.email}></CopyValue>
          </AnimatedContentLoader>
        </>
      }
    />
  )
}

const PageContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PeriodListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
`
