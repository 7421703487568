import { AnimatedContentLoader, Tab, Tabs, TeaserView } from '@components'
import { DocumentBankDetails } from '@containers/DocumentBankDetails'
import { FeatureWrapper, useBusinessContext } from '@context'
import { fetchDocument, IImportData } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { DocumentAttachmentPreview } from './DocumentAttachmentPreview'
import { ChatbaseEmbed } from '@containers/ChatbaseEmbed'
import { FaCommentDots } from 'react-icons/fa6'
import styled from 'styled-components'
interface Props {
  documentId?: number
  importData?: IImportData
  attachmentIds: number[]
}

export const DocumentFormExtraDetailView: React.FC<Props> = ({
  documentId,
  importData,
  attachmentIds
}) => {
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(
    [businessId, 'documents', documentId],
    () => fetchDocument({ businessId, documentId }),
    {
      enabled: !!documentId && !importData
    }
  )

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      <Tabs queryKey="extra" wrapperStyles={{ height: '100%' }}>
        <Tab key="attachments" name={t('document.formExtra.tabAttachments')}>
          <DocumentAttachmentPreview attachmentIds={attachmentIds} />
        </Tab>

        <Tab key="ai-help" name={t('document.formExtra.aiHelp')} icon={<FaCommentDots />}>
          <FeatureWrapper feature="feature_bank_integrations">
            <ChatbaseEmbedWrapper>
              <ChatbaseEmbed botId={t('document.formExtra.aiHelpBotId')} />
            </ChatbaseEmbedWrapper>
          </FeatureWrapper>

          <FeatureWrapper feature="feature_bank_integrations" reverse>
            <TeaserView
              header={t('teaser.aiHelp.header')}
              subHeader={t('teaser.aiHelp.subHeader')}
              features={[t('teaser.feature.1'), t('teaser.feature.4'), t('teaser.feature.5')]}
            />
          </FeatureWrapper>
        </Tab>

        {importData ? (
          <Tab key="import-data" name={t('document.formExtra.tabImportData')}>
            <DocumentBankDetails import_data={importData || data.import_data} />
          </Tab>
        ) : (
          data &&
          data.import_data && (
            <Tab key="import-data" name={t('document.formExtra.tabImportData')}>
              <DocumentBankDetails import_data={importData || data.import_data} />
            </Tab>
          )
        )}
      </Tabs>
    </AnimatedContentLoader>
  )
}

const ChatbaseEmbedWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`
