import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.md}rem;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  align-items: center;

  &.isMobile {
    padding: ${({ theme }) => theme.spacing.md}rem;
  }
`

export const HeaderSpacer = styled.div`
  flex: 1;
`

export const RightMenuWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
`

export const HamburgerButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.metalGray};
  background: none;
  width: ${({ theme }) => theme.iconSize.lg}rem;
  height: ${({ theme }) => theme.iconSize.lg}rem;
`

export const AIHelpButton = styled.button`
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 100px;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  font-weight: 500;
  border: none;
  outline: none !important;
  background: transparent;
  color: ${({ theme }) => theme.colors.nocfoPurple};

  [data='ai-help-bg'] {
    opacity: 0.1;
  }

  span {
    z-index: 1;
  }

  svg {
    z-index: 1;
    fill: ${({ theme }) => theme.colors.nocfoPurple};
    min-width: ${({ theme }) => theme.iconSize.xs}rem;
    min-height: ${({ theme }) => theme.iconSize.xs}rem;
  }

  &.isMobile {
    & > span {
      display: none;
    }
  }

  &:hover {
    [data='ai-help-bg'] {
      opacity: 1;
    }
    svg,
    span {
      animation: none;
      color: ${({ theme }) => theme.colors.neutralWhite};
      fill: ${({ theme }) => theme.colors.neutralWhite};
    }
  }
`

export const AIHelpBg = styled.div.attrs({
  data: 'ai-help-bg'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  color: ${({ theme }) => theme.colors.neutralWhite};
  ${({ theme }) => `
      background: linear-gradient(-45deg, ${theme.colors.nocfoBlue}, ${theme.colors.nocfoPurple}, ${theme.colors.nocfoBlue});
    `}
  background-size: 800% 800%;
  animation: gradient 5s ease infinite;
  transition: 0.4s;

  svg {
    fill: ${({ theme }) => theme.colors.neutralWhite};
    width: ${({ theme }) => theme.iconSize.xs}rem;
    height: ${({ theme }) => theme.iconSize.xs}rem;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`
