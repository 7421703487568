import {
  InvoiceDeliveryMethod,
  InvoiceStatus,
  PurchaseInvoiceImportSource,
  ReferenceMethods,
  VatCode
} from '@constants'
import { network } from '@utils'
import { IContact } from './contacts'
import { IFile } from './files'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'

/* =======
 PRODUCTS
======= */

export interface IProduct {
  id: number
  code: string
  name: string
  unit: string
  amount: number
  vat_rate: number
  vat_code: VatCode
  is_vat_inclusive: boolean
  vat_exclusive_amount: number
  vat_inclusive_amount: number
  vat_amount: number
}

interface ProductListProps {
  businessId: string
}

export const fetchProducts: QueryFunction<ProductListProps, PaginatedResponse<IProduct>> = async (
  { businessId },
  params = null
) => {
  const url = `/v1/invoicing/${businessId}/product/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createProduct: MutationFunction<ProductListProps, IProduct> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/invoicing/${businessId}/product/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface ProductInstanceProps extends ProductListProps {
  productId: number
}

export const fetchProduct: QueryFunction<ProductInstanceProps, IProduct> = async (
  { businessId, productId },
  params = null
) => {
  const url = `/v1/invoicing/${businessId}/product/${productId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updateProduct: QueryFunction<ProductInstanceProps, IProduct> = async (
  { businessId, productId },
  requestData
) => {
  const url = `/v1/invoicing/${businessId}/product/${productId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteProduct: MutationFunction<ProductInstanceProps, void> = async (
  { businessId, productId },
  params = null
) => {
  const url = `/v1/invoicing/${businessId}/product/${productId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE',
    params
  })
}

/* =======
 INVOICES
======= */

export interface IRecurenceRule {
  start: Date
  rule: {
    frequency: number
    by_month_day: Array<number>
    by_month: Array<number>
  }
}

export interface IInvoiceRecurence extends IRecurenceRule {
  recurrence_rule: IRecurenceRule
  is_active_recurrence_template: boolean
  is_recurrence_end_exceeded: boolean
  recurrence_parent_id?: number
  recurrence_reference_method: ReferenceMethods
  recurrence_email_subject?: string
  recurrence_email_content?: string
  next_recurrences?: Array<Date>
  recurrence_end: Date | string
  is_recurrence_active: boolean
  is_recurrence_applied: boolean
  delivery_method: InvoiceDeliveryMethod
}

export interface IInvoiceRow {
  id: number
  product?: number
  code: string
  name: string
  unit: string
  amount: number
  vat_code: VatCode
  vat_rate: number
  product_count: number
  description: string
  total_amount: number
  total_vat_amount: number
}

export interface IInvoice extends IInvoiceRecurence {
  id: number
  friendly_name: string
  receiver: number
  receiver_info?: IContact
  contact_person?: string
  invoice_number: string
  invoicing_date: string
  payment_condition_days: number
  due_date: string
  reference: string
  penalty_interest: number
  currency: string
  vat_country: string
  description: string
  is_credit_note_for: number
  credit_notes: number[]
  rows: IInvoiceRow[]
  pdf?: IFile
  total_amount: number
  status: InvoiceStatus
  total_vat_amount: number
  is_editable?: boolean
  is_past_due: boolean
  is_registered_to_kravia: boolean
  earliest_debt_collection_date: string
  delivery_method: InvoiceDeliveryMethod
  last_delivery_at: string
  attachments: number[]
}

export interface IPurchaseInvoice {
  id: number
  invoice_number: string
  invoicing_date: string
  due_date: string
  sender_vat: string
  sender_name: string
  sender_bank_account: string
  sender_bank_bic?: string
  receiver_vat: string
  receiver_name: string
  reference: string
  original_invoice_xml: string
  original_attachment: IFile
  amount: number
  currency: string
  virtuaaliviivakoodi?: string
  is_paid: boolean
  is_past_due: boolean
  attachments: number[]
  import_source: PurchaseInvoiceImportSource
}

interface InvoiceListProps {
  businessId: string
}

export const fetchInvoices: QueryFunction<InvoiceListProps, PaginatedResponse<IInvoice>> = async (
  { businessId },
  params
) => {
  const url = `/v1/invoicing/${businessId}/invoice/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: params
  })
  return data
}

interface IPurchaseInvoiceItem {
  type: 'purchase'
  item: IPurchaseInvoice
}

interface InvoiceItem {
  type: 'sales'
  item: IInvoice
}

export type ICombinedInvoice = InvoiceItem | IPurchaseInvoiceItem

export const fetchCombinedInvoices: QueryFunction<
  InvoiceListProps,
  PaginatedResponse<ICombinedInvoice>
> = async ({ businessId }, params) => {
  const url = `/v1/invoicing/${businessId}/invoice_combined/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createInvoice: MutationFunction<InvoiceListProps, IInvoice> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/invoicing/${businessId}/invoice/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface InvoiceInstanceProps extends InvoiceListProps {
  businessId: string
  invoiceId: number
}

export const fetchInvoice: QueryFunction<InvoiceInstanceProps, IInvoice> = async (
  { businessId, invoiceId },
  params = null
) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updateInvoice: MutationFunction<InvoiceInstanceProps, IInvoice> = async (
  { businessId, invoiceId },
  requestData
) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteInvoice: MutationFunction<InvoiceInstanceProps, void> = async (
  { businessId, invoiceId },
  params = null
) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE',
    params
  })
}

interface PurchaseInvoiceInstanceProps extends InvoiceListProps {
  businessId: string
  purchaseInvoiceId: number
}

export const fetchPurchaseInvoice: QueryFunction<
  PurchaseInvoiceInstanceProps,
  IPurchaseInvoice
> = async ({ businessId, purchaseInvoiceId }, params = null) => {
  const url = `/v1/invoicing/${businessId}/purchase_invoice/${purchaseInvoiceId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updatePurchaseInvoice: MutationFunction<
  PurchaseInvoiceInstanceProps,
  IPurchaseInvoice
> = async ({ businessId, purchaseInvoiceId }, requestData) => {
  const url = `/v1/invoicing/${businessId}/purchase_invoice/${purchaseInvoiceId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export interface IInvoiceDeliveryMethod {
  method: InvoiceDeliveryMethod
  errors: string[]
}

export const fetchInvoiceDeliveryMethods: QueryFunction<
  InvoiceInstanceProps,
  IInvoiceDeliveryMethod[]
> = async ({ businessId, invoiceId }, params = null) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/delivery_methods/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export interface SendInvoiceViaEmailProps {
  email_subject?: string
  email_content?: string
}

export interface SendInvoiceProps {
  delivery_method: InvoiceDeliveryMethod
  data?: SendInvoiceViaEmailProps
}

export const sendInvoice: MutationFunction<InvoiceInstanceProps, SendInvoiceProps> = async (
  { businessId, invoiceId },
  requestData
) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/send/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true,
    timeout: 120000 // 2 min
  })
  return handleReturn({ status, data })
}

export const acceptInvoice: MutationFunction<InvoiceInstanceProps, null> = async ({
  businessId,
  invoiceId
}) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/actions/accept/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const setInvoiceAsPaid: MutationFunction<InvoiceInstanceProps, null> = async ({
  businessId,
  invoiceId
}) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/actions/paid/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const setInvoiceAsUnpaid: MutationFunction<InvoiceInstanceProps, null> = async ({
  businessId,
  invoiceId
}) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/actions/unpaid/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const setInvoiceAsCreditLoss: MutationFunction<InvoiceInstanceProps, null> = async ({
  businessId,
  invoiceId
}) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/actions/credit_loss/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface FetchInvoiceNumberProps {
  businessId: string
}

export interface IInvoiceNumber {
  next_invoice_number: IInvoice['invoice_number']
}

export const fetchInvoiceNumber: QueryFunction<FetchInvoiceNumberProps, IInvoiceNumber> = async ({
  businessId
}) => {
  const url = `/v1/invoicing/${businessId}/invoice_number/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface UpdateInvoiceNumberProps {
  businessId: string
}

export const updateInvoiceNumber: MutationFunction<
  UpdateInvoiceNumberProps,
  IInvoiceNumber
> = async ({ businessId }, requestData) => {
  const url = `/v1/invoicing/${businessId}/invoice_number/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export interface DisableInvoiceRecurrenceProps {
  businessId: string
  invoiceId: number
}

export const disableInvoiceRecurrence: MutationFunction<
  DisableInvoiceRecurrenceProps,
  undefined
> = async ({ businessId, invoiceId }) => {
  const url = `/v1/invoicing/${businessId}/invoice/${invoiceId}/actions/disable_recurrence/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export interface IEmailReceiveAddress {
  email: string
}

interface EmailReceiveAddressProps {
  businessId: string
}

export const fetchEmailInvoiceAddress: QueryFunction<
  EmailReceiveAddressProps,
  IEmailReceiveAddress
> = async ({ businessId }) => {
  const url = `/v1/invoicing/${businessId}/email_receive_address/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
