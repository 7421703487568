import { Button } from '@root/components'
import { motion, Variants } from 'framer-motion'
import styled from 'styled-components'

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.3
    }
  }
}

export const Page = styled(motion.div).attrs({
  variants: container,
  initial: 'hidden',
  animate: 'show'
})<{ isAnimating?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md}rem;
  height: 100%;

  color: ${({ theme }) => theme.colors.neutralBlack};

  overflow: ${({ isAnimating }) => (isAnimating ? 'hidden' : 'visible')};
`

export const HeaderContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme, isMobile }) => (isMobile ? theme.spacing.md : theme.spacing.xxl)}rem;
`
export const Header = styled.h1`
  margin: 0;
`

export const HeaderText = styled.p`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  text-align: center;
`

export const ContentContainer = styled.div<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? 32 : 64)}px;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme, isMobile }) => (isMobile ? theme.spacing.lg : theme.spacing.xxl)}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxl}rem;
  justify-content: center;
`

export const ActionButton = styled(Button)`
  justify-content: center;
  width: 100%;
`
