import React, { useContext, useState } from 'react'

interface Props {
  isInitialized: boolean
  setInitialized: (value: boolean) => void
  isAIHelpOpen: boolean
  setAIHelpOpen: (value: boolean) => void
}

export const AIHelpContext = React.createContext<Props>(null)

export const useAIHelpContext = (): Props => {
  return useContext(AIHelpContext)
}

interface AIHelpProviderProps {
  children: React.ReactNode
}

export const AIHelpProvider: React.FC<AIHelpProviderProps> = ({ children }) => {
  const [isAIHelpOpen, setAIHelpOpen] = useState(false)
  const [isInitialized, setInitialized] = useState(false)

  return (
    <AIHelpContext.Provider
      value={{
        isAIHelpOpen,
        setAIHelpOpen,
        isInitialized,
        setInitialized
      }}
    >
      {children}
    </AIHelpContext.Provider>
  )
}
