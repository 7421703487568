import { useQueryParam } from '@hooks'
import { theme } from '@root/styles'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { isValidElement, useEffect } from 'react'
import { FaCircle } from 'react-icons/fa'
import styled from 'styled-components'

interface TabsProps {
  queryKey?: string
  children: Array<React.ReactElement<TabProps>> | React.ReactElement<TabProps>
  wrapperStyles?: React.CSSProperties
}

export const Tabs: React.FC<TabsProps> = ({
  queryKey = 'tab',
  children: _children,
  wrapperStyles
}) => {
  const [active, setActive] = useQueryParam<string>(queryKey)

  const children = (Array.isArray(_children) ? _children : [_children]).filter(
    isValidElement
  ) as Array<React.ReactElement<TabProps>>
  const activeKey = active || children[0]?.key

  // If any of the tabs is active, set the first tab as active
  useEffect(() => {
    if (!children.some(children => children.key === activeKey)) {
      setActive(children[0].key as any)
    }
  }, [activeKey])

  return (
    <StyledTabsWrapper className="tabs-wrapper" style={{ ...(wrapperStyles ?? {}) }}>
      <TabsScrollable>
        <StyledTabs className="tabs-header">
          {children.map(({ key, props: { icon, name, notification_dot } }) => (
            <StyledTabButton
              key={`tab-${key}`}
              className={cn({ isSelected: key === activeKey })}
              onClick={() => setActive(key as any)}
            >
              {icon} {name} {<NotificationIcon type={notification_dot} />}
            </StyledTabButton>
          ))}
        </StyledTabs>
      </TabsScrollable>

      <AnimatePresence mode="wait">
        {children.filter(({ key }) => key === activeKey)}
      </AnimatePresence>
    </StyledTabsWrapper>
  )
}

interface TabProps {
  key: string
  name: string
  icon?: React.ReactNode
  notification_dot?: NotificationUrgency
  children: React.ReactNode
}

export const Tab: React.FC<TabProps> = ({ children }) => {
  return <StyledTab className="tabs-tab">{children}</StyledTab>
}

const BORDER_WIDTH = '2px'

const StyledTabButton = styled.button`
  background: white;
  border: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.defaultHover};
  margin-bottom: -${BORDER_WIDTH};
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
  outline: none !important;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  background: ${({ theme }) => theme.colors.defaultHover};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  &.isSelected {
    border: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.neutralGray};
    border-bottom: ${BORDER_WIDTH} solid white;
    background: ${({ theme }) => theme.colors.neutralWhite};
  }
`

const TabsScrollable = styled.div`
  overflow-x: scroll;
  overflow-y: visible;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledTabs = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  white-space: nowrap;

  border-bottom: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.neutralGray};
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
`

const StyledTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTab = styled(motion.div).attrs({
  initial: { opacity: 0, y: -5 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 5 }
})`
  flex: 1;
`

export const enum NotificationUrgency {
  URGENT = 'urgent',
  NON_URGENT = 'non_urgent'
}

interface NotificationIconProps {
  type: NotificationUrgency
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({ type }) => {
  const style = {
    width: `${theme.spacing.sm}rem`,
    height: `${theme.spacing.sm}rem`
  }

  const Urgent = () => <FaCircle color={theme.colors.nocfoRed} style={style} />
  const NonUrgent = () => <FaCircle color={theme.colors.nocfoBlue} style={style} />

  const icon: Record<NotificationUrgency, React.ReactNode> = {
    urgent: <Urgent />,
    non_urgent: <NonUrgent />
  }

  return icon[type]
}
