import React, { useState } from 'react'
import { IInvoice, kraviaOnboardStatus, kraviaRegisterInvoice } from '@query'
import { Button, ImageMessageBox, ModalV2 } from '@components'
import supportImgSrc from '@assets/undraw/undraw_showing_support_re_5f2v.svg'
import timeImgSrc from '@assets/undraw/undraw_time_management_re_tk5w.svg'
import { OnboardingView } from '@pages/InvoicingPage/DebtCollection/trackerMessages/Onboarding/OnboardingView'
import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useBusinessContext } from '@context'
import styled from 'styled-components'
import { formatCurrency } from '@utils'
import dayjs from 'dayjs'
import { SendInvoiceForm } from '@pages/InvoicingPage/SendInvoiceForm.tsx'
import { FaPaperPlane } from 'react-icons/fa'

interface Props {
  invoice: IInvoice
}

const MINIMUM_AMOUNT_TO_REGISTER = 1

export const StartDebtCollectionMessage: React.FC<Props> = ({ invoice }) => {
  const { t } = useTranslation()
  const [isOnboardingVisible, setIsOnboardingVisible] = useState(false)
  const [showSend, setShowSend] = useState(false)
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()

  const earliestDebtCollectionDate = dayjs(invoice?.earliest_debt_collection_date, 'YYYY-MM-DD')
  const isTooEarlyForDebtCollection = earliestDebtCollectionDate.isAfter(dayjs())
  const hasTooSmallAmount =
    invoice?.total_amount + invoice?.total_vat_amount < MINIMUM_AMOUNT_TO_REGISTER

  const { data } = useQuery([businessId, 'kravia_onboard_status'], () =>
    kraviaOnboardStatus({ businessId })
  )

  const { mutateAsync, isLoading: isRegistering } = useMutation<unknown, unknown, number>(
    invoiceId => kraviaRegisterInvoice({ businessId, invoiceId }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([businessId, 'invoices', invoice.id]),
          queryClient.invalidateQueries([businessId, 'kravia_invoice_details', invoice?.id])
        ])
      }
    }
  )

  if (!data?.kravia_verified) {
    return (
      <>
        <ImageMessageBox imgSrc={supportImgSrc} fillBackground={false}>
          <h4>{t('invoicing.debtCollection.register.title')}</h4>
          <p>
            <Trans
              i18nKey="invoicing.debtCollection.register.description1"
              components={[
                <a
                  key="debt-collection-link"
                  href={t('link.docs.debtCollection')}
                  target="_blank"
                  rel="noreferrer"
                />
              ]}
            ></Trans>
          </p>
          <p>
            <Trans i18nKey="invoicing.debtCollection.register.description2" />
          </p>

          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button
              type="button"
              icon={<FaMoneyBillTrendUp />}
              onClick={() => setIsOnboardingVisible(true)}
              intent="primary"
            >
              {t('invoicing.actions.onboardToKravia')}
            </Button>
          </div>
        </ImageMessageBox>

        <ModalV2
          isVisible={isOnboardingVisible}
          handleOnClose={() => setIsOnboardingVisible(false)}
          width={600}
          height={800}
          header={t('invoicing.debtCollection.onboard.title')}
        >
          <OnboardingView data={data} onRegisterInvoice={() => mutateAsync(invoice.id)} />
        </ModalV2>
      </>
    )
  }

  if (isTooEarlyForDebtCollection) {
    return (
      <>
        <ImageMessageBox imgSrc={timeImgSrc} fillBackground={false}>
          <h4>{t('invoicing.debtCollection.register.tooEarly')}</h4>
          <p>
            {t('invoicing.debtCollection.register.tooEarlyInfo1', {
              date: earliestDebtCollectionDate.format('DD.MM.YYYY')
            })}
          </p>
          <p>{t('invoicing.debtCollection.register.tooEarlyInfo2')}</p>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button
              type="button"
              icon={<FaPaperPlane />}
              onClick={() => setShowSend(true)}
              intent="primary"
            >
              {t('invoicing.debtCollection.register.sendReminder')}
            </Button>
          </div>
        </ImageMessageBox>

        <ModalV2
          header={t('invoicing.send.title')}
          isVisible={showSend}
          handleOnClose={() => setShowSend(false)}
        >
          <SendInvoiceForm
            invoice={invoice}
            onSubmit={() => setShowSend(false)}
            onCancel={() => setShowSend(false)}
          />
        </ModalV2>
      </>
    )
  }

  return (
    <>
      <ImageMessageBox imgSrc={supportImgSrc} fillBackground={false}>
        <h4>{t('invoicing.debtCollection.register.title')}</h4>
        <p>
          <Trans
            i18nKey="invoicing.debtCollection.register.description1"
            components={[
              <a
                key="debt-collection-link"
                href={t('link.docs.debtCollection')}
                target="_blank"
                rel="noreferrer"
              />
            ]}
          ></Trans>
        </p>
        <p>
          <Trans i18nKey="invoicing.debtCollection.register.description2" />
        </p>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            type="button"
            icon={<FaMoneyBillTrendUp />}
            onClick={() => mutateAsync(invoice.id)}
            disabled={isRegistering || hasTooSmallAmount}
            showSpinner={isRegistering}
            intent="primary"
          >
            {t('invoicing.actions.sendToDebtCollection')}
          </Button>
        </div>

        {hasTooSmallAmount && (
          <TotalAmountWarning>
            {t('invoicing.debtCollection.register.totalAmountWarning', {
              amount: formatCurrency(MINIMUM_AMOUNT_TO_REGISTER)
            })}
          </TotalAmountWarning>
        )}
      </ImageMessageBox>

      <ModalV2
        isVisible={isOnboardingVisible}
        handleOnClose={() => setIsOnboardingVisible(false)}
        width={600}
        height={800}
        header={t('invoicing.debtCollection.onboard.title')}
      >
        <OnboardingView data={data} onRegisterInvoice={() => mutateAsync(invoice.id)} />
      </ModalV2>
    </>
  )
}

const TotalAmountWarning = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.orange};
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`
