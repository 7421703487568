import { useAIHelpContext } from '@context'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useScreen } from '@utils'
import { SideSheet } from '@components'
import { AIHelpContent } from '@containers/AIHelpSheet/AIHelpContent.tsx'
import { useEffect } from 'react'

const SHEET_WIDTH = 380

export const AIHelpSheet: React.FC = () => {
  const { xl: forBigScreen } = useScreen()
  const { isAIHelpOpen, setAIHelpOpen, setInitialized } = useAIHelpContext()

  useEffect(() => {
    setInitialized(true)
    return () => setInitialized(false)
  }, [])

  return forBigScreen ? (
    <AnimatePresence initial={false}>
      {isAIHelpOpen && (
        <StyledSideSheet>
          <AIHelpContent />
        </StyledSideSheet>
      )}
    </AnimatePresence>
  ) : (
    <SideSheet isShown={isAIHelpOpen} handleClose={() => setAIHelpOpen(false)}>
      <AIHelpContent />
    </SideSheet>
  )
}

export const StyledSideSheet = styled(motion.div).attrs({
  initial: { width: 0 },
  animate: { width: SHEET_WIDTH },
  exit: { width: 0 }
})`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.menuBg};
  min-width: 0;
  overflow: hidden;

  & > div {
    position: relative;
    height: 100%;
    margin-left: ${({ theme }) => theme.spacing.xs}rem;
    width: ${SHEET_WIDTH}px;
  }
`
