import { CopyValue, TextTooltip, ToggleContainer } from '@components'
import { IPurchaseInvoice } from '@query'
import { theme } from '@root/styles'
import { formatEurosWithOpts } from '@utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTriangleExclamation } from 'react-icons/fa6'
import styled from 'styled-components'

interface Props {
  invoice: IPurchaseInvoice
}

export const PurchaseInvoicePaymentInfo: React.FC<Props> = ({ invoice }) => {
  const { t } = useTranslation()

  return (
    <>
      <ToggleContainer
        title={t('invoicing.purchaseInvoiceDetails.paymentInfo')}
        openByDefault={!invoice.is_paid}
      >
        <StyledPaymentInfoWrapper>
          <Row>
            <Column>
              <Header>{t('invoicing.purchaseInvoiceDetails.iban')}</Header>
              <CopyValue value={invoice.sender_bank_account} />
            </Column>

            {invoice.sender_bank_bic && (
              <Column>
                <Header>{t('invoicing.purchaseInvoiceDetails.bic')}</Header>
                <CopyValue value={invoice.sender_bank_bic} />
              </Column>
            )}
          </Row>

          <Row>
            <Column>
              <Header>{t('invoicing.purchaseInvoiceDetails.receiver')}</Header>
              <CopyValue value={invoice.sender_name} />
            </Column>

            <Column>
              <Header>{t('invoicing.purchaseInvoiceDetails.amount')}</Header>
              <CopyValue
                value={formatEurosWithOpts(invoice.amount, {
                  withEuroSymbol: false,
                  inCents: false
                })}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <Header>{t('invoicing.purchaseInvoiceDetails.reference')}</Header>
              <Row
                style={{ gap: `${theme.spacing.sm}rem`, paddingLeft: `${theme.spacing.xxs}rem` }}
              >
                {!invoice.reference && (
                  <MissingDataAlert
                    tooltip={t('invoicing.purchaseInvoiceDetails.missingReference')}
                  />
                )}
                <Column>
                  <CopyValue value={invoice.reference} />
                </Column>
              </Row>
            </Column>
          </Row>

          {invoice.virtuaaliviivakoodi && (
            <>
              <br />
              <Row>
                <Column>
                  <Header>{t('invoicing.purchaseInvoiceDetails.virtuaaliviivakoodi')}</Header>
                  <CopyValue value={invoice?.virtuaaliviivakoodi} />
                </Column>
              </Row>
            </>
          )}
        </StyledPaymentInfoWrapper>
      </ToggleContainer>
    </>
  )
}

const MissingDataAlert: React.FC<{ tooltip: string }> = ({ tooltip }) => {
  return (
    <TextTooltip tooltip={tooltip} placement="bottom-start">
      <StyledWarningIcon />
    </TextTooltip>
  )
}

const StyledWarningIcon = styled(FaTriangleExclamation)`
  color: ${({ theme }) => theme.colors.nocfoYellow};
  height: ${({ theme }) => theme.iconSize.sm}rem;
  width: ${({ theme }) => theme.iconSize.sm}rem;
`

const StyledPaymentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const Row = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  align-items: center;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  padding: ${({ theme }) => theme.spacing.xxs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`
